import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function SunSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="4" stroke={color} strokeWidth="2" />
      <path
        d="M12 2V5M12 19V22M22 12H19M5 12H2M4.92898 4.92893L7.0503 7.05025M16.9498 16.9497L19.0711 19.0711M19.0711 4.92893L16.9498 7.05025M7.0503 16.9497L4.92898 19.0711"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  )
}
