/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-unknown-property */
import React from "react"
import { svgs } from ".."
import { MapPinsProps } from "../../types/Svg"
import cssStyle from "./Pins.module.css"

export default function BasePin({
  style,
  className,
  active = false,
  star,
  onClick,
}: MapPinsProps) {
  const pin = (
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <path
        d="M32 16.4C32 25.4575 20 40 16 40C12 40 0 25.4575 0 16.4C0 7.34253 7.1634 0 16 0C24.8366 0 32 7.34253 32 16.4Z"
        fill={active ? "white" : "var(--goboat-blue)"}
      />
      <path
        d="M26.1 14.1L18 7.3C16.9 6.3 15.1 6.4 14 7.3L6 14.3C5.7 14.5 5.5 14.8 5.5 15.2C5.5 15.5 5.6 15.9 5.8 16.1C6 16.3 6.2 16.4 6.5 16.5C6.7 16.6 7 16.6 7.2 16.5L7.3 23.1C7.3 23.7 7.5 24.2 8 24.6C8.4 25 9 25.2 9.5 25.2H13.7C14 25.2 14.4 25.1 14.6 24.8C14.9 24.6 15 24.2 15 23.9L14.9 18.9H17.2L17.3 23.9C17.3 24.2 17.4 24.5 17.7 24.8C17.9 25 18.3 25.2 18.7 25.2H22.9C23.5 25.2 24 25 24.4 24.6C24.8 24.2 25 23.7 25 23.1L24.9 16.5C25.4 16.6 25.9 16.5 26.2 16.1C26.3 16 26.4 15.9 26.4 15.7C26.7 15.1 26.6 14.5 26.1 14.1ZM12.4 22.6H10.1L10 14.4L15.9 9.3C16 9.2 16.2 9.2 16.4 9.3L22.4 14.3L22.5 22.5H20.2L20.1 17.5C20.1 16.8 19.5 16.2 18.8 16.2H13.8C13.4 16.2 13.1 16.3 12.9 16.6C12.7 16.9 12.5 17.2 12.5 17.5L12.4 22.6Z"
        fill={active ? "var(--goboat-blue)" : "white"}
      />
    </svg>
  )

  if (star) {
    return (
      <div style={{ position: "relative" }}>
        <svgs.Star className={cssStyle.pinStar} height="16px" width="16px" />
        {pin}
      </div>
    )
  }

  return pin
}
