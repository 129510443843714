import React, { useEffect, useRef, useState } from "react"
import { svgs } from "../../img"
import style from "./Faq.module.css"

function FaqItem({ obj }: { obj: { question: string; answer: string } }) {
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<null | HTMLParagraphElement>(null)

  useEffect(() => {
    if (expanded && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [expanded])

  return (
    <div className={style.questionAnswer}>
      <button
        type="button"
        className={style.row}
        onClick={() => setExpanded(!expanded)}
      >
        <p className={style.question}>{obj.question}</p>
        {expanded ? <svgs.ChevronUp /> : <svgs.ChevronDown />}
      </button>

      <p
        ref={ref}
        className={style.answer}
        style={{ display: expanded ? "block" : "none" }}
      >
        {obj.answer}
      </p>
    </div>
  )
}

export default function Faq({
  content,
}: {
  content?: { question: string; answer: string }[]
}) {
  return (
    <div className={style.container}>
      <p>FAQ</p>
      {content?.map((obj) => {
        return <FaqItem obj={obj} />
      })}
    </div>
  )
}
