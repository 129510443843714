import React from "react"
import Screen from "./Screen"
import Slider from "../components/slider/Slider"
import ItemsContainer from "../components/items-container/ItemsContainer"
import Footer from "../components/footer/Footer"
import styles from "./Home.module.css"
import { useLocale } from "../contexts/LocaleContext"
import Partnerships from "../components/homeUI/Partnerships"
import Events from "../components/homeUI/Events"
import Articles from "../components/homeUI/Articles"
import SafetySections from "../components/homeUI/SafetySections"
import Pois from "../components/homeUI/Pois"
import Promoted from "../components/homeUI/Promoted"
import { useCMS } from "../contexts/SanityContext"

/* eslint no-underscore-dangle: 0 */
export default function Home() {
  const { strings } = useLocale()
  // TODO: CREATE function to get tags from cms, create predefined tags
  // TODO: image placeholder!!!
  const { promoted, articles, partnerships, events, safetySections, pois } =
    useCMS()
  return (
    <Screen>
      {promoted !== undefined && promoted.length !== 0 && (
        <>
          <h2 className={styles.title}>{strings.topHeader}</h2>
          <Slider id="top">
            <Promoted />
          </Slider>
        </>
      )}

      <div className={styles.border} />

      {articles !== undefined && articles.length !== 0 && (
        <ItemsContainer title={strings.articles} pathToAll="/articles">
          <Articles />
        </ItemsContainer>
      )}
      {events !== undefined && events.length !== 0 && (
        <ItemsContainer title={strings.events} pathToAll="/events">
          <Slider padding="0 0 16px 16px">
            <Events />
          </Slider>
        </ItemsContainer>
      )}
      {partnerships !== undefined && partnerships.length !== 0 && (
        <ItemsContainer title={strings.partnerships} pathToAll="/partnerships">
          <Partnerships />
        </ItemsContainer>
      )}
      {safetySections !== undefined && safetySections.length !== 0 && (
        <ItemsContainer title={strings.safety} pathToAll="/safety">
          <Slider padding="0 0 16px 16px">
            <SafetySections />
          </Slider>
        </ItemsContainer>
      )}
      {pois !== undefined && pois.length !== 0 && (
        <ItemsContainer title={strings.poi} pathToAll="/pois" hideBorder>
          <Pois />
        </ItemsContainer>
      )}

      <Footer />
    </Screen>
  )
}
