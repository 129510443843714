import { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"

export default function ScrollHandling() {
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (history.action === "PUSH") {
      // eslint-disable-next-line no-undef
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}
