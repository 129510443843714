import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function GoboatSvg({
  color = "white",
  height = "20px",
  width = "81px",
}: CustomSvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 81 20"
      width={width}
      height={height}
    >
      <g fill={color}>
        <path d="M11.7461 14.5911V9.12042H6.06451c-.15574.00027-.30501.06233-.41503.17256-.11003.11022-.17182.25959-.17182.41533v.70379c0 .1558.06179.3051.17182.4154.11002.1102.25929.1722.41503.1725h3.35718v3.5911c0 1.7752-1.09017 3.5618-3.52948 3.5618-2.19913 0-3.56706-1.3668-3.56706-3.5618V5.67657c0-2.37665 1.35749-3.79575 3.63807-3.79575 2.73064 0 3.27155 2.078 3.34151 4.07769.00325.10149.0458.19774.11866.26846.07286.07072.17034.11037.27188.11059h1.54653c.0525-.00011.1045-.01077.1528-.03134.0484-.02057.0921-.05064.1286-.08842.0366-.03779.0651-.08251.084-.13154.0189-.04902.0278-.10134.0262-.15386C11.4997 1.78266 9.78403.0012207 5.9674.0012207 2.23534.0012207.00488281 2.02492.00488281 5.41238v9.17872c0 3.3864 2.20017719 5.4101 5.89150719 5.4101 4.31681 0 5.85391-2.7922 5.85391-5.4101h-.0042zm-11.064561 0V5.41238c0-2.99796 2.021621-4.85981 5.280641-4.85981 3.24335 0 5.03002 1.85245 5.03002 5.21485 0 0 .0094.13784-.1702.13784h-.6955c-.0184.00211-.0371-.00004-.0545-.00628-.0175-.00625-.0333-.01642-.0462-.02974-.0129-.01332-.0226-.02943-.02833-.04708-.0057-.01766-.00727-.03638-.00459-.05474 0-1.65718-.52211-4.43795-4.0307-4.43795-3.86363 0-4.28132 3.04078-4.28132 4.3471v8.91453c0 2.5563 1.57573 4.0819 4.2124 4.0819 3.10969 0 4.21244-2.1992 4.21244-4.0819v-4.1414H6.45087c-.08585 0-.16819-.0341-.22889-.0948-.06071-.0607-.09482-.143-.09482-.2289v-.12948c0-.08585.03411-.16819.09482-.2289.0607-.06071.14304-.09481.22889-.09481H11.105v4.91829c0 2.9708-2.04357 4.889-5.20861 4.889-3.21412 0-5.20963-1.8733-5.20963-4.889M24.345 14.4134V5.73596c0-1.63317-.543-4.37634-4.1769-4.37634-3.9075 0-4.2103 3.32689-4.2103 4.3471v8.61688c0 1.6134.5471 4.32 4.2103 4.32 3.4511 0 4.1769-2.2973 4.1769-4.2302zm-7.7419-.0898V5.70672c0-2.37665 1.3335-3.79784 3.566-3.79784 2.2535 0 3.495 1.35749 3.495 3.82708v8.67744c0 2.4258-1.2102 3.7102-3.495 3.7102-2.2659 0-3.566-1.3857-3.566-3.8z" />
        <path d="M26.0252 14.5595V5.44033c0-3.4073-2.1928-5.43935344-5.8549-5.43935344-4.344 0-5.8905 2.81000344-5.8905 5.43935344v9.11917c0 3.4574 2.1469 5.4404 5.8905 5.4404 5.0937 0 5.8549-3.4094 5.8549-5.4404zm-11.0688 0V5.44033c0-3.00527 2.0457-4.948569 5.2107-4.948569 3.8146 0 5.1731 2.557299 5.1731 4.948569v9.11917c0 3.1724-1.8378 4.9194-5.1731 4.9194-3.2632 0-5.2107-1.8389-5.2107-4.9194zM30.7336 10.4485v8.0175h3.1515c2.9029 0 4.1383-1.1946 4.1383-3.9952 0-2.7818-1.3575-4.0223-4.3931-4.0223h-2.8967zm6.6131 4.0223c0 1.6175-.3937 3.4742-3.4585 3.4742h-2.5061v-6.9473h2.2524c2.5322 0 3.7133 1.1059 3.7133 3.4731" />
        <path d="M33.0089.206788h-3.5243c-.0568-.000137-.1132.010944-.1657.032608-.0526.021664-.1004.053486-.1406.093643-.0403.040157-.0722.087861-.094.140379-.0218.052518-.033.108819-.033.165678V19.1145c0 .0933.0183.1856.054.2718.0357.0861.088.1644.1539.2303.066.066.1443.1183.2304.1539.0862.0357.1785.0541.2717.0541h4.2333c3.9733 0 5.7433-1.6509 5.7433-5.3537 0-1.4619-.3927-3.4188-2.266-4.49019l-.1807-.10442.1692-.11486c1.2792-.86149 1.8796-2.19287 1.8796-4.20196 0-3.60361-2.0717-5.352682-6.3301-5.352682h-.001zm2.9635 9.745732c2.6722.69958 3.0878 3.09928 3.0878 4.51838 0 3.2778-1.6092 4.8034-5.0645 4.8034h-3.9681c-.0878 0-.172-.0349-.234-.097-.0621-.062-.097-.1462-.097-.234V.957585c0-.03012.0059-.059942.0175-.087756.0116-.027814.0285-.05307.0499-.074319.0213-.021249.0466-.038072.0745-.049503.0279-.011431.0577-.017245.0878-.017107h3.0815c3.801 0 5.6482 1.57886 5.6482 4.83057 0 2.22838-.8792 3.67045-2.6857 4.39305" />
        <path d="M33.0821 1.53516h-2.3485V9.6707h2.6033c2.8685 0 4.322-1.3836 4.322-4.11111 0-2.66903-1.5392-4.02443-4.5768-4.02443zm.2548 7.58419h-1.96V2.05727h1.7052c2.5479 0 3.895 1.21234 3.895 3.50232 0 2.32966-1.2531 3.55976-3.6402 3.55976zM44.3889 5.70675v8.61485c0 1.6154.5493 4.3199 4.2135 4.3199 3.4532 0 4.1769-2.2973 4.1769-4.227V5.73598c0-1.63525-.541-4.38051-4.1769-4.38051-3.9096 0-4.2135 3.33107-4.2135 4.35128zm7.7074.02923v8.67852c0 2.4247-1.2081 3.7049-3.4939 3.7049-2.2671 0-3.5661-1.3815-3.5661-3.7978V5.70675c0-2.3777 1.3335-3.7968 3.5661-3.7968 2.2524 0 3.4939 1.35749 3.4939 3.82603z" />
        <path d="M48.6024 0c-4.3439 0-5.8915 2.81001-5.8915 5.4404v9.1192c0 3.4574 2.148 5.4415 5.8915 5.4415 5.0948 0 5.856-3.4094 5.856-5.4415V5.4404C54.4584 2.0331 52.2656 0 48.6024 0zm5.1742 14.5596c0 3.1713-1.8368 4.9193-5.1742 4.9193-3.2632 0-5.2085-1.8378-5.2085-4.9193V5.4404c0-3.00736 2.0445-4.948571 5.2085-4.948571 3.8156 0 5.1742 2.556261 5.1742 4.948571v9.1192zM59.0664 19.4581l1.5434-5.3454h4.9475l1.4191 5.2723c.0334.1202.1053.2262.2047.3016.0994.0755.2208.1163.3456.1161h1.1236c.0971.0003.1931-.022.2802-.0651.0871-.0431.1629-.1059.2216-.1834.0586-.0775.0984-.1675.1163-.2631.0178-.0955.0132-.1938-.0135-.2873L64.087.61505c-.0342-.121808-.1073-.229081-.2082-.305442-.1008-.076362-.2239-.117617-.3504-.117468h-.9691c-.1256-.000245-.248.040361-.3486.115699-.1005.075338-.1739.181326-.209.30199L56.7482 19.1939c-.0201.0717-.0234.1471-.0095.2203.0138.0733.0445.1423.0895.2016.045.0594.1032.1076.1699.1407.0668.0331.1403.0504.2148.0503h1.3909c.1045 0 .2062-.0341.2896-.097.0835-.0629.1442-.1513.173-.2517zM62.8548.712163h.3885c.0576.000157.1135.019113.1593.053984.0458.034871.079.083749.0944.139197L68.62 19.0832c.0058.0202.0069.0415.003.0622-.0038.0207-.0123.0402-.025.057-.0127.0168-.0291.0305-.0479.0399-.0188.0094-.0396.0142-.0606.0142h-.6328c-.056-.0002-.1103-.0187-.1548-.0526s-.0767-.0815-.0917-.1353l-1.5183-5.478h-6.0857l-1.512 5.4487c-.0176.0625-.055.1175-.1066.1567-.0517.0392-.1147.0604-.1796.0605h-.567c-.0294 0-.0584-.0068-.0847-.0199-.0264-.0131-.0493-.0322-.067-.0557-.0177-.0235-.0297-.0507-.0351-.0797-.0054-.0289-.004-.0587.0041-.0869l5.1428-18.11c.0155-.055343.0487-.104086.0945-.138781.0458-.034696.1018-.053436.1592-.053356z" />
        <path d="M62.9302 3.22459l-2.7014 9.55991h5.6388l-2.7077-9.55991c-.0051-.02672-.0194-.05081-.0404-.06814-.0209-.01733-.0473-.02681-.0745-.02681-.0272 0-.0535.00948-.0745.02681-.021.01733-.0352.04142-.0403.06814zm.1127 1.95374l1.9684 7.08397h-3.9325l1.9641-7.08397zM74.6798 19.7808h1.2635c.1407 0 .2756-.0559.3751-.1553.0995-.0995.1554-.2344.1554-.3751V2.04053h3.7393c.0461.00014.0918-.00884.1344-.02642.0427-.01759.0814-.04343.114-.07603.0326-.03261.0585-.07135.0761-.11398.0175-.04263.0265-.08831.0264-.13443V.514923c.0001-.046114-.0089-.0918-.0264-.134431-.0176-.04263-.0435-.081363-.0761-.113971-.0326-.032608-.0713-.058447-.114-.07603-.0426-.017584-.0883-.026565-.1344-.026427h-9.8032c-.0461-.000138-.0918.008843-.1344.026427-.0426.017583-.0814.043422-.114.07603-.0326.032608-.0584.071341-.076.113971-.0176.042631-.0266.088317-.0264.134431V1.68863c-.0002.04616.0088.0919.0264.13459.0176.04269.0434.08149.076.11418s.0713.05863.1139.07633c.0427.01769.0884.0268.1345.0268h3.7394V19.2493c-.0003.0698.0133.139.0398.2035.0266.0646.0656.1232.1149.1726s.1079.0886.1724.1152c.0645.0267.1336.0404.2034.0402zM70.741 1.30644V.925303c0-.055389.022-.10851.0611-.147676.0392-.039166.0923-.061169.1477-.061169H79.69c.03 0 .0597.005915.0875.017407.0277.011493.0529.028338.0742.049573.0212.021236.0381.046445.0495.074191.0115.027745.0174.057482.0174.087514v.348767c0 .03003-.0059.05977-.0174.08752-.0114.02774-.0283.05295-.0495.07419-.0213.02123-.0465.03808-.0742.04957-.0278.01149-.0575.01741-.0875.01741h-3.8637v17.4542c0 .0739-.0293.1448-.0816.1971-.0523.0523-.1232.0817-.1972.0817h-.4782c-.0367.0001-.073-.007-.1069-.021-.0339-.0139-.0647-.0344-.0906-.0604-.0259-.0259-.0464-.0567-.0604-.0906-.014-.0338-.0211-.0702-.0209-.1068V1.51842h-3.8376c-.0279.00042-.0557-.00477-.0816-.01527-.0259-.01051-.0494-.02611-.0692-.04587-.0198-.01977-.0354-.04331-.0459-.06922-.0105-.02591-.0157-.05366-.0152-.08162z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={color} d="M0 0h80.5639v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
