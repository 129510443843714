import React, { useRef, useState, useEffect } from "react"
import Screen from "../Screen"
import TaggedItem from "../../components/tagged-item/TaggedItem"
import { getCategoryTag, getTagTags } from "../../components/tag/Tag"
import { ViewOnMapButton } from "../../components/buttons/Button"
import { useCMS } from "../../contexts/SanityContext"
import { urlFor } from "../../util/sanity"
import { FilterComponent } from "../../components/filter/Filter"
import { useLocale } from "../../contexts/LocaleContext"
import globalstyle from "../../globalstyles.module.css"

/* eslint no-underscore-dangle: 0 */
export default function AllPois() {
  const { pois } = useCMS()
  const { strings } = useLocale()
  const poisFilters = useRef<{ id: string; name: string }[]>([])
  const [tagIds, setTagIds] = useState<string[]>([])
  const [filteredPois, setFilteredPois] = useState<any>(pois)

  useEffect(() => {
    if (tagIds.length !== 0) {
      const result = pois?.filter(
        (post: { category: { _id: string }; tags: string | any[] }) => {
          for (let i = 0; i < tagIds.length; i += 1) {
            if (tagIds[i] === post.category._id) {
              return true
            }

            for (let x = 0; x < post.tags?.length; x += 1) {
              if (post.tags !== null && post.tags[x] !== null) {
                if (tagIds.includes(post.tags[x]._id)) {
                  return true
                }
              }
            }
          }
          return false
        }
      )
      setFilteredPois(result)
    } else {
      setFilteredPois(pois)
    }
  }, [tagIds, pois])

  const poisUi = filteredPois?.map((poi: any) => {
    if (poi.category != null) {
      if (poisFilters.current.find((v) => v.id === poi.category._id) == null) {
        const obj = { id: poi.category._id, name: poi.category.name }
        poisFilters.current = [...poisFilters.current, obj]
      }
    }
    poi.tags?.map((tag: any) => {
      if (tag !== null) {
        if (poisFilters.current.find((v) => v.id === tag._id) == null) {
          const obj = { id: tag._id, name: tag.name }
          poisFilters.current = [...poisFilters.current, obj]
        }
      }
      return null
    })

    return (
      <TaggedItem
        key={poi._id}
        imgSrc={urlFor(poi.hero).size(320, 320).url()!}
        title={poi.title}
        tags={[getCategoryTag(poi), getTagTags(poi)]}
        hideBorder
        subtitle={
          poi.hours ? poi.hours?.replace(/\n/g, ", ") : strings.timeNotAvailable
        }
        button={
          <div className={globalstyle.buttonWrapper}>
            {poi.location && <ViewOnMapButton id={poi._id} />}
          </div>
        }
        text={`${poi.description?.substring(0, 148)}${
          poi.description?.length > 148 ? "..." : ""
        }`}
        className={globalstyle.taggedItem}
        to={`/poi/${poi._id}`}
        star={poi.recommended}
      />
    )
  })

  return (
    <Screen>
      <div className={globalstyle.heading}>
        <h1>{strings.poi}</h1>
        <FilterComponent
          className={globalstyle.filter}
          filters={poisFilters.current}
          callback={setTagIds}
        />
      </div>
      {poisUi}
    </Screen>
  )
}
