import React from "react"
import { useLocale } from "../../contexts/LocaleContext"
import { svgs } from "../../img"
import style from "./ContactInfo.module.css"

export default function ContactInfo({ poi }: { poi: any }) {
  const { strings } = useLocale()

  if (!poi?.contact) return null

  function address() {
    const splitAddress = poi.contact?.address
      .split("\n")
      .map((str: string) => <p>{str}</p>)
    return splitAddress
  }

  function splitHours() {
    const splitWorkHours = poi?.hours
      .split("\n")
      .map((str: string) => <p>{str}</p>)
    return splitWorkHours
  }

  function phoneNumber() {
    const spacedPhoneNumber = poi?.contact.phone?.replace(
      /(\d)(?=(\d{2})+$)/g,
      "$1 "
    )
    return spacedPhoneNumber
  }
  return (
    <div className={style.container}>
      {poi?.hours && (
        <div>
          <h2>{strings.openingHours}</h2>
          <div>{splitHours()}</div>
        </div>
      )}

      {(poi?.contact.address || poi?.contact.phone || poi?.contact.mail) && (
        <div>
          <h2>{strings.contactInfo}</h2>
          {poi?.contact.address && (
            <div className={style.flex}>
              <svgs.Position width="14px" />
              <div>
                <a
                  href={strings.mapLink + poi?.contact.address}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {address()}
                </a>
              </div>
            </div>
          )}
          {poi?.contact.phone && (
            <div className={style.flex}>
              <svgs.Telephone width="14px" />
              <a href={`tel:${phoneNumber()}`}>{phoneNumber()}</a>
            </div>
          )}
          {poi?.contact.mail && (
            <div className={style.flex}>
              <svgs.Mail width="14px" />
              {poi.contact.mail}
            </div>
          )}
          {poi?.contact.link && (
            <div className={style.flex}>
              <svgs.Link width="14px" />
              <a
                href={poi.contact.link}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {poi.contact.link}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
