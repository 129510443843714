import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function PositionSvg({
  color = "#959DAD",
  width = "12px",
  height = "100%",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.0049 1.74489L6.46937 10.2302L5.53349 6.2163L1.51962 5.28043L10.0049 1.74489Z"
          stroke={color}
          strokeWidth="0.9975"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
