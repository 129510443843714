import React from "react"
import Navbar from "../components/navbar/Navbar"
import Actionbar from "../components/actionbar/Actionbar"
import styles from "./Screen.module.css"
import useWindowDimensions from "./useWindowDimensions"

export default function Screen({ children }: { children?: React.ReactNode }) {
  const { width, height } = useWindowDimensions()
  const screenWidth = width
  const screenHeight = height
  return (
    <div
      style={{ height: screenHeight, width: screenWidth }}
      className={styles.container}
    >
      <Navbar />
      <div className={styles.content}>{children}</div>
      <Actionbar />
    </div>
  )
}
