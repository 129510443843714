import React, { createContext, useState, useEffect, useContext } from "react"
import sanityClient from "../util/sanity"

const SanityContext = createContext<{
  partnerships?: any[]
  events?: any[]
  articles?: any[]
  pois?: any[]
  safetySections?: any[]
  promoted?: any[]
  safetyPage?: any[]
}>({})

export function useCMS() {
  return useContext(SanityContext)
}

export default function SanityProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [content, setContent] = useState<{
    partnerships?: any[]
    events?: any[]
    articles?: any[]
    pois?: any[]
    safetySections?: any[]
    promoted?: any[]
    safetyPage?: any[]
  }>({})

  /* eslint no-underscore-dangle: 0 */
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type in ["partnership", "event", "article", "poi", "safetySection", "safetyPage"]]{
          ...,
          category->,
          format->,
          tags[]->,
          safetySections[]->,
          _type == "partnership" => {
            "category": poi->{"name": category->name},
            "hours": poi->hours
          },

      }`
      )
      .then((data) => {
        const promoted = data?.filter((item: any) => item.recommended)

        const partnerships = data?.filter(
          (item: any) => item._type === "partnership"
        )

        const events = data?.filter((item: any) => item._type === "event")

        const articles = data?.filter((item: any) => item._type === "article")

        const pois = data?.filter((item: any) => item._type === "poi")

        const safetySections = data?.filter(
          (item: any) => item._type === "safetySection"
        )

        const safetyPage = data?.filter(
          (item: any) => item._type === "safetyPage"
        )

        setContent({
          promoted,
          partnerships,
          events,
          articles,
          pois,
          safetySections,
          safetyPage,
        })
      })
      .catch()
  }, [])

  return (
    <SanityContext.Provider value={content}>{children}</SanityContext.Provider>
  )
}
