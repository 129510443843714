import React from "react"
import PortableText from "react-portable-text"
import ReactPlayer from "react-player/youtube"
import { urlFor } from "../util/sanity"
import style from "./BlockContent.module.css"

// const factboxSerializers = {

// }

// TODO: FIX h1-h6 sizing & margins
const serializers = {
  h1: ({ children }: any) => (
    <h1 style={{ margin: "20px 0 7px", fontSize: "27px" }}>{children}</h1>
  ),
  h2: ({ children }: any) => (
    <h2 style={{ margin: "20px 0 7px", fontSize: "20px" }}>{children}</h2>
  ),
  h3: ({ children }: any) => (
    <h3 style={{ margin: "20px 0 7px", fontSize: "16px" }}>{children}</h3>
  ),
  h4: ({ children }: any) => (
    <h4 style={{ margin: "20px 0 7px", fontSize: "14px" }}>{children}</h4>
  ),
  h5: ({ children }: any) => (
    <h5 style={{ margin: "20px 0 7px", fontSize: "13px" }}>{children}</h5>
  ),
  h6: ({ children }: any) => (
    <h6 style={{ margin: "20px 0 7px", fontSize: "12px" }}>{children}</h6>
  ),
  normal: ({ children }: any) => (
    <p style={{ margin: "12px 0", fontSize: "14px" }}>{children}</p>
  ),
  figure: ({ caption, image, alt }: any) => (
    <div className={style.imgWrapper}>
      <img
        src={urlFor(image).size(764, 446).url()!}
        className={style.img}
        alt={alt}
      />
      {caption != null && <p className={style.videoText}>{caption}</p>}
    </div>
  ),
  factbox: ({ body }: any) => (
    <PortableText
      className={style.factbox}
      content={body}
      serializers={serializers}
    />
  ),
  youtube: ({ url }: any) => (
    <div
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        height: "200px",
        margin: "20px 0",
      }}
    >
      <ReactPlayer url={url} width="100%" height="100%" controls />
    </div>
  ),
}

export default function BlockContent({
  blocks,
  className,
}: {
  blocks: any
  className?: string
}) {
  return (
    <PortableText
      className={className || style.portableText}
      content={blocks}
      serializers={serializers}
    />
  )
}
