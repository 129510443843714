/* eslint-disable no-underscore-dangle */
import { DateTime } from "luxon"
import { useLocale } from "../contexts/LocaleContext"
import { subtitleSelector } from "../logic/sanityHelpers"

const useSubtitle = () => {
  const { strings } = useLocale()

  const getSubtitle = (item: any) => {
    if (subtitleSelector[item._type] === "datetime")
      return DateTime.fromISO(item.datetime).toFormat("D - HH:mm")
    if (subtitleSelector[item._type] === "_createdAt")
      return DateTime.fromISO(item._createdAt).toFormat("D - HH:mm")
    if (subtitleSelector[item._type] === "hours") {
      return item.hours
        ? item.hours?.replace(/\n/g, ", ")
        : strings.timeNotAvailable
    }

    return item[subtitleSelector[item._type]]
  }

  return getSubtitle
}

export default useSubtitle
