import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ReactPlayer from "react-player/youtube"
import styles from "../../components/tag/Tag.module.css"

import sanityClient, { urlFor } from "../../util/sanity"
import Screen from "../Screen"
import {
  ExternalButton,
  ViewOnMapButton,
} from "../../components/buttons/Button"

import style from "./DetailPoi.module.css"
import ContactInfo from "../../components/contactInfo/ContactInfo"
import { getCategoryTag, getTagTags } from "../../components/tag/Tag"

/* eslint no-underscore-dangle: 0 */
export default function DetailPoi() {
  const { id } = useParams<{ id: string }>()
  const [poi, setPoi] = useState<any>(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_id == "${id}"]{
          ...,
          category->,
          tags[]->,
          _type == "poi" => {
            "category": poi->{"name": category->name},
            "hours": poi->hours
          },
        }`
      )
      .then((data) => setPoi(data[0]))
      .catch()
  }, [])

  // TODO: add top tags
  // TODO: fix youtube video

  return (
    <Screen>
      <div className={style.container}>
        {poi?.title && <h1 className={style.subhead}>{poi.title}</h1>}

        <div className={styles.tag}>
          {[getCategoryTag(poi), getTagTags(poi)]}
        </div>

        <div className={style.imgWrapper}>
          {poi?.hero ? (
            <>
              <img
                src={urlFor(poi.hero).size(764, 446).url()!}
                className={style.img}
                alt=""
              />
            </>
          ) : (
            <div className={style.img} style={{ background: "grey" }} />
          )}
        </div>
        {poi?.location && <ViewOnMapButton id={poi._id} />}
        {poi?.description && <p>{poi.description}</p>}
        {poi?.video?.url && (
          <div className={style.videoWrapper}>
            <ReactPlayer
              url={poi.video.url}
              width="100%"
              height="100%"
              controls
            />
          </div>
        )}
        {poi?.cta && (
          <ExternalButton
            text={poi.cta.text}
            onClick={(e) => {
              e.preventDefault()
              window.open(poi.cta.url, "_blank", "noopener noreferrer nofollow")
            }}
          />
        )}
        <ContactInfo poi={poi} />
      </div>
    </Screen>
  )
}
