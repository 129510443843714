/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { useCMS } from "../../contexts/SanityContext"
import { urlFor } from "../../util/sanity"
import SmallSliderItem from "../sliderItems/SmallSliderItem"

export default function SafetySections() {
  const { safetySections } = useCMS()

  const safetySectionsUi = safetySections
    ?.filter((v, i) => i < 5)
    .map((safetySection: any) => (
      <SmallSliderItem
        key={safetySection._id}
        title={safetySection.title}
        imgSrc={urlFor(safetySection.hero).size(500, 249).url()!}
        to={`/safety/${safetySection._id}`}
      />
    ))

  return <>{safetySectionsUi}</>
}
