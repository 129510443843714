import React, { useMemo } from "react"
import Screen from "./Screen"
import SafetyItem from "../components/safety-item/SafetyItem"
import { getFirstParagraph } from "../logic/blockContent"
import { urlFor } from "../util/sanity"
import { useCMS } from "../contexts/SanityContext"
import globalstyle from "../globalstyles.module.css"
import BlockContent from "./BlockContent"
import style from "./Safety.module.css"
import { ExternalButton } from "../components/buttons/Button"

/* eslint no-underscore-dangle: 0 */
export default function SafetyScreen() {
  const { safetyPage } = useCMS()

  // TODO: no subtitle & image text in sanity
  const safetySections = useMemo(
    () =>
      safetyPage?.map((safety) => {
        return (
          <>
            <div className={globalstyle.heading}>
              <h1>{safety.headline}</h1>
            </div>
            <div className={style.container}>
              {safety?.body && <BlockContent blocks={safety.body} />}
            </div>
            {safety.safetySectionsHeadline ? (
              <section className={style.safetyPosts}>
                <div className={style.headingPosts}>
                  <h1>{safety.safetySectionsHeadline}</h1>
                </div>
                {safety.safetyDescription ? (
                  <p className={style.safetyDesc}>{safety.safetyDescription}</p>
                ) : null}
                {safety.safetySections?.map(
                  (section: {
                    title: string | undefined
                    body: any
                    hero: any
                    _id: string | undefined
                  }) => (
                    <SafetyItem
                      title={section.title}
                      text={getFirstParagraph(section.body)}
                      imgSrc={urlFor(section?.hero).size(686, 400).url()!}
                      id={section._id}
                    />
                  )
                )}
              </section>
            ) : null}
            {safety.quizHeadline ? (
              <section className={style.quiz}>
                <div className={style.heading}>
                  <h1>{safety.quizHeadline}</h1>
                </div>
                {safety.quizDescription ? (
                  <p>{safety.quizDescription}</p>
                ) : null}
                <ExternalButton
                  text={safety?.cta.text}
                  onClick={(e) => {
                    e.preventDefault()
                    window.open(
                      safety.cta.url,
                      "_blank",
                      "noopener noreferrer nofollow"
                    )
                  }}
                />
              </section>
            ) : null}
          </>
        )
      }),
    [safetyPage]
  )

  return <Screen>{safetySections}</Screen>
}
