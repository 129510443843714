export type Colors =
  | "goboat-blue"
  | "blue"
  | "medium-blue"
  | "light-blue"
  | "light-grey"
  | "yellow"
  | "green"
  | "red"
  | "white"
  | "pink"
  | "purple"

export const colorsArray: Colors[] = [
  "light-blue",
  "goboat-blue",
  "blue",
  "medium-blue",
  "light-grey",
  "yellow",
  "green",
  "red",
  "white",
  "pink",
  "purple",
]

export const poppingColors: Colors[] = [
  "goboat-blue",
  "blue",
  "yellow",
  "green",
  "red",
  "pink",
  "purple",
]
