import React from "react"
import { Link } from "react-router-dom"
import { svgs } from "../../img"
import style from "./SmallSliderItem.module.css"

export default function SmallSliderItem({
  imgSrc,
  title,
  subtitle,
  to,
}: {
  imgSrc: string
  title: string
  subtitle?: string
  to: string
}) {
  return (
    <Link to={to} className={style.item}>
      {/* TODO: stuff in the item comes from cms */}
      <img src={imgSrc} alt="img" className={style.img} />
      <p className={style.title}>{title}</p>
      {subtitle && (
        <p className={style.text}>
          <svgs.Info /> {subtitle}
        </p>
      )}
    </Link>
  )
}
