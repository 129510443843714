import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function SnowSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92108 8.0789L10.1285 8.87772L10.4482 7.46565C10.9024 5.45859 12.6372 4 14.6667 4C17.0311 4 19 5.98549 19 8.5C19 11.0145 17.0311 13 14.6667 13H7.55556C6.17294 13 5 11.8353 5 10.3333C5 8.83135 6.17294 7.66667 7.55556 7.66667C8.0577 7.66667 8.52497 7.81685 8.92108 8.0789Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M7.5 17L5 19.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 17L11 19.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 17L17 19.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 19.5L5 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 19.5L11 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 19.5L17 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}
