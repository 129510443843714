import React, { useContext, useEffect, useState } from "react"
import Strings, {
  LocaleData,
  setCurrentLocale,
  setReactCallback,
} from "../i18n/Strings"

const LocaleContext: React.Context<LocaleData> = React.createContext(Strings)

export function useLocale() {
  return useContext(LocaleContext)
}

export function LocaleProvider({ children }: { children: React.ReactNode }) {
  const [locale, setLocaleData] = useState(Strings)

  useEffect(() => {
    const navLocale = window.navigator.language.split("-")[0]
    setReactCallback(setLocaleData)
    setCurrentLocale(navLocale)
  }, [])

  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  )
}

export default LocaleContext
