import React from "react"
import { useLocale } from "../../contexts/LocaleContext"
import { svgs } from "../../img"
import style from "./Footer.module.css"

export default function Footer() {
  const { strings } = useLocale()
  return (
    <div className={style.footer}>
      <h2 className={style.title}>{strings.contactInfo}</h2>
      <div className={style.content}>
        <div className={style.infoSection}>
          <svgs.Home width="14px" />
          <a
            href="https://g.page/goboatcph?share"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <p>{strings.address}</p>
            <p>{strings.zipCode}</p>
          </a>
        </div>
        <div className={style.infoSection}>
          <svgs.Telephone width="14px" />
          <div>
            <a href={`tel:${strings.phoneNumber}`}>{strings.phoneNumber}</a>
            <p>{strings.phoneTime}</p>
          </div>
        </div>
        <div className={style.infoSection}>
          <svgs.Mail width="14px" />
          <div>
            <a href="mailto:info@goboat.dk">info@goboat.dk</a>
            <p>CVR: 35 66 24 05</p>
          </div>
        </div>
      </div>
    </div>
  )
}
