/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { useCMS } from "../../contexts/SanityContext"
import useItemType from "../../hooks/useItemType"
import useSubtitle from "../../hooks/useSubtitle"
import { titleSelector } from "../../logic/sanityHelpers"
import { urlFor } from "../../util/sanity"
import BigSliderItem from "../sliderItems/BigSliderItem"

export default function Promoted() {
  const { promoted } = useCMS()
  const getItemType = useItemType()
  const getSubtitle = useSubtitle()

  const promotedUi = promoted
    ?.filter((v, i) => i < 10)
    .map((item: any) => (
      <BigSliderItem
        key={item._id}
        title={item[titleSelector[item._type]]}
        imgSrc={urlFor(item.hero).size(764, 446).url()!}
        tags={getItemType(item)}
        subtitle={getSubtitle(item)}
        to={
          item._type === "safetySection"
            ? `/safety/${item._id}`
            : `/${item._type}/${item._id}`
        }
      />
    ))

  return <>{promotedUi}</>
}
