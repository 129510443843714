// import style from "../containers/DetailEvent.module.css"

/* eslint no-underscore-dangle: 0 */

// export function BlockContent(blocks: any[]) {
//   return blocks.map((block) => {
//     if (block._type !== "block" || !block.children) {
//       return ""
//     }
//     // loop through the children spans, and join the
//     // text strings
//     return block.children.map((child: any) => child.text).join("")
//   })
// }

export function toJsx(): any {
  // if (block._type === "block") {
  //   if (block.style === "normal") {
  //     const text = block.children.map((child: any) => child.text).join("")
  //     return <p className={style.text}>{text}</p>
  //   }
  //   if (block.style === "subhead") {
  //     const text = block.children.map((child: any) => child.text).join("")
  //     return <p className={style.subhead}>{text}</p>
  //   }
  // }
  // if (block._type === "image") {
  //   const imgSrc = block.asset.url
  //   const imgText = block.imageText
  //   return (
  //     <div className={style.imgWrapper}>
  //       <img src={imgSrc} alt="" className={style.img} />
  //       <p className={style.videoText}>{imgText}</p>
  //     </div>
  //   )
  // }
  return null
}

export function getFirstParagraphBlock(blocks: any) {
  return blocks.find((block: any) => {
    const isBlock = block._type === "block"
    if (!isBlock) return false
    if (block.style === "normal") return true
    return false
  })
}

export function getFirstParagraph(blocks: any) {
  return getFirstParagraphBlock(blocks)
    .children.map((child: any) => child.text)
    .join("")
}
