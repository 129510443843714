import React from "react"
import { svgs } from ".."
import { MapPinsProps } from "../../types/Svg"
import cssStyle from "./Pins.module.css"

export default function AttractionPin({
  style,
  className,
  active = false,
  star,
  onClick,
}: MapPinsProps) {
  const pin = (
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <g filter="url(#filter0_d)">
        <path
          d="M38 18.4C38 27.4575 26 42 22 42C18 42 6 27.4575 6 18.4C6 9.34253 13.1634 2 22 2C30.8366 2 38 9.34253 38 18.4Z"
          fill={active ? "white" : "#7146A8"}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 20C30 20.2103 29.901 20.6311 29.4916 21.2277C29.0948 21.8058 28.4841 22.4315 27.6923 23.0122C26.0913 24.1862 23.9964 25 22 25C20.0036 25 17.9087 24.1862 16.3077 23.0122C15.5159 22.4315 14.9052 21.8058 14.5084 21.2277C14.099 20.6311 14 20.2103 14 20C14 19.7897 14.099 19.3689 14.5084 18.7723C14.9052 18.1942 15.5159 17.5685 16.3077 16.9878C17.9087 15.8138 20.0036 15 22 15C23.9964 15 26.0913 15.8138 27.6923 16.9878C28.4841 17.5685 29.0948 18.1942 29.4916 18.7723C29.901 19.3689 30 19.7897 30 20ZM32 20C32 23 27 27 22 27C17 27 12 23 12 20C12 17 17 13 22 13C27 13 32 17 32 20ZM22 22C23.1046 22 24 21.1046 24 20C24 18.8954 23.1046 18 22 18C20.8954 18 20 18.8954 20 20C20 21.1046 20.8954 22 22 22ZM22 24C24.2091 24 26 22.2091 26 20C26 17.7909 24.2091 16 22 16C19.7909 16 18 17.7909 18 20C18 22.2091 19.7909 24 22 24ZM23 20C23 20.5523 22.5523 21 22 21C21.4477 21 21 20.5523 21 20C21 19.4477 21.4477 19 22 19C22.5523 19 23 19.4477 23 20Z"
        fill={active ? "#7146A8" : "white"}
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0 0.364706 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )

  if (star) {
    return (
      <div style={{ position: "relative" }}>
        <svgs.Star className={cssStyle.pinStar} height="16px" width="16px" />
        {pin}
      </div>
    )
  }

  return pin
}
