import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function SwimzoneSvg({
  color = "white",
  height = "100%",
  width = "12px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.138717 3.70832C0.0890025 3.70862 0.0432003 3.73534 0.0184779 3.77847C-0.00624457 3.82161 -0.0061533 3.87463 0.0187174 3.91768C0.0187174 3.91768 0.508035 4.76366 1.54905 5.41732C2.59005 6.07095 4.19589 6.53386 6.39258 5.80029C6.39258 5.80026 6.39272 5.80023 6.39286 5.8002C8.12178 5.22647 9.2943 5.07244 10.1226 5.08946C10.9509 5.10648 11.4328 5.29099 11.817 5.41693C11.8796 5.43747 11.9481 5.41136 11.9812 5.35433C12.0142 5.2973 12.0029 5.2249 11.9539 5.18073C11.0382 4.35435 10.1393 4.0158 9.24314 3.94157C8.34699 3.86735 7.45767 4.0507 6.53404 4.24493C4.68679 4.63338 2.71042 5.07587 0.20579 3.72504C0.185204 3.71395 0.162158 3.7082 0.138772 3.70832H0.138717V3.70832Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01787 0C4.94061 0.00139534 4.85896 0.0118743 4.77338 0.0334435C4.77333 0.0334434 4.77329 0.0334434 4.77324 0.0334435C4.45759 0.1133 3.89192 0.338764 3.2801 0.604922C2.66828 0.871079 2.01908 1.17634 1.56733 1.41173C1.56728 1.41176 1.56723 1.41179 1.56719 1.41182C1.37279 1.5133 1.23941 1.64297 1.1683 1.78716C1.09719 1.93135 1.09276 2.09061 1.15133 2.22277C1.26849 2.48709 1.61326 2.61472 1.99264 2.48322C3.18067 2.07139 3.85994 1.81622 4.54607 1.59443C4.5462 1.5944 4.54623 1.59438 4.54633 1.59435C4.74498 1.53058 4.80448 1.54907 4.80184 1.54747C4.80053 1.54664 4.80415 1.54747 4.81623 1.57102C4.82802 1.59397 4.84389 1.63414 4.86856 1.68238C4.94361 1.83868 4.98248 1.91894 5.00975 1.97397C4.24206 2.56339 3.61688 3.00449 2.75776 3.626C2.71362 3.65794 2.69211 3.71265 2.70267 3.7661C2.71323 3.81955 2.75393 3.86196 2.8069 3.87472C2.8069 3.87472 3.88808 4.13672 5.12072 3.87695C6.32218 3.62381 6.52097 3.53502 7.5586 3.40323C7.56031 3.40326 7.56203 3.40326 7.56375 3.40323C7.59901 3.39737 7.64927 3.3897 7.70472 3.36679C7.76016 3.3438 7.82846 3.29854 7.86229 3.21875C7.89614 3.13895 7.88636 3.04707 7.85014 2.95686C7.8139 2.86665 7.75104 2.76862 7.65182 2.64727C7.65177 2.64722 7.65173 2.64718 7.65168 2.64713C7.46359 2.41781 7.04852 1.91562 6.66108 1.44539C6.27363 0.975165 5.908 0.529185 5.84018 0.440402C5.83995 0.440122 5.83972 0.439843 5.83949 0.439565C5.75638 0.332512 5.63485 0.196441 5.45795 0.103069C5.33633 0.0388769 5.18772 -0.00226742 5.01776 0.00073116L5.01787 0V0ZM9.23005 0.0501443C8.47824 0.0501443 7.86458 0.662356 7.86458 1.41557C7.86458 2.16754 8.47806 2.78099 9.23005 2.78099C9.98328 2.78099 10.5955 2.16735 10.5955 1.41557C10.5955 0.662547 9.98308 0.0501443 9.23005 0.0501443V0.0501443Z"
        fill={color}
      />
    </svg>
  )
}
