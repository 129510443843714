export const titleSelector = {
  partnership: "headline",
  event: "title",
  article: "headline",
  poi: "title",
  safetySection: "title",
} as { [key: string]: string }

export const subtitleSelector = {
  partnership: "hours",
  event: "datetime",
  article: "_createdAt",
  poi: "hours",
  safetySection: "no-subtitle",
} as { [key: string]: string }
