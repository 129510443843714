import React from "react"
import { BrowserRouter } from "react-router-dom"
import WeatherProvider from "../contexts/WeatherContext"
import SanityProvider from "../contexts/SanityContext"
import { LocaleProvider } from "../contexts/LocaleContext"
import Main from "./Routes"
import ScrollHandling from "../ScrollHandling"

function App() {
  return (
    <BrowserRouter>
      <WeatherProvider>
        <SanityProvider>
          <LocaleProvider>
            <ScrollHandling />
            <Main />
          </LocaleProvider>
        </SanityProvider>
      </WeatherProvider>
    </BrowserRouter>
  )
}

export default App
