/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { useCMS } from "../../contexts/SanityContext"
import useSubtitle from "../../hooks/useSubtitle"
import { urlFor } from "../../util/sanity"
import { getTagTags } from "../tag/Tag"
import TaggedItem from "../tagged-item/TaggedItem"
import styles from "../../containers/Home.module.css"

export default function Articles() {
  const { articles } = useCMS()
  const getSubtitle = useSubtitle()

  const articlesUi = articles
    ?.filter((v, i) => i < 3)
    .map((article: any, i: number, arr: any[]) => (
      <TaggedItem
        key={article._id}
        title={article.headline}
        imgSrc={urlFor(article.hero).size(320, 320).url()!}
        subtitle={getSubtitle(article)}
        hideBorder={i === arr.length - 1}
        hideInfo
        tags={[getTagTags(article)]}
        className={styles.taggedItem}
        star={article.recommended}
        to={`/article/${article._id}`}
      />
    ))

  return <>{articlesUi}</>
}
