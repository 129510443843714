import React from "react"
import { svgs } from ".."
import { MapPinsProps } from "../../types/Svg"
import cssStyle from "./Pins.module.css"

export default function RestaurantPin({
  style,
  className,
  active = false,
  star,
  onClick,
}: MapPinsProps) {
  const pin = (
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <g filter="url(#filter0_d)">
        <path
          d="M38 18.4C38 27.4575 26 42 22 42C18 42 6 27.4575 6 18.4C6 9.34253 13.1634 2 22 2C30.8366 2 38 9.34253 38 18.4Z"
          fill={active ? "white" : "#DE0078"}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16V11H16V16C16 16.7403 16.4022 17.3866 17 17.7324V11H19V17.7324C19.5978 17.3866 20 16.7403 20 16V11H22V16C22 17.8638 20.7252 19.4299 19 19.874V29H17V19.874C15.2748 19.4299 14 17.8638 14 16ZM27.0001 11C26.0879 11 25.4253 11.4893 25.0026 12.1467C24.6112 12.7556 24.3961 13.5405 24.2636 14.3356C23.9999 15.9177 24 17.9836 24 19.9338V20C24 20.5523 24.4477 21 25 21H26.0001V29H28.0001V11H27.0001Z"
        fill={active ? "#DE0078" : "white"}
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0 0.364706 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )

  if (star) {
    return (
      <div style={{ position: "relative" }}>
        <svgs.Star className={cssStyle.pinStar} height="16px" width="16px" />
        {pin}
      </div>
    )
  }

  return pin
}
