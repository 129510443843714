import axios from "axios"

export default async function fetchWeather() {
  const resp = await axios
    .get(
      "https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/12.5929/lat/55.6716/data.json"
    )
    .catch()

  const dataNearestTime = resp && resp.data.timeSeries[0].parameters
  const temperature: number | undefined = dataNearestTime?.find(
    (obj: any) => obj.name === "t"
  )?.values[0]
  const windSpeed: number | undefined = dataNearestTime?.find(
    (obj: any) => obj.name === "ws"
  )?.values[0]
  const weatherSymbolKey: number | undefined = dataNearestTime?.find(
    (obj: any) => obj.name === "Wsymb2"
  )?.values[0]

  return { temperature, windSpeed, weatherSymbolKey }
}
