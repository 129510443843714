import React from "react"
import { Link } from "react-router-dom"
import { useLocale } from "../../contexts/LocaleContext"
import style from "./SafetyItem.module.css"

export default function SafetyItem({
  title,
  subtitle,
  text,
  imgSrc,
  imgText,
  id,
  hideBorder = false,
}: {
  title?: string
  subtitle?: string
  text?: string
  imgSrc?: string
  imgText?: string
  id?: string
  hideBorder?: boolean
}) {
  const { strings } = useLocale()
  return (
    <Link to={`/safety/${id}`} className={style.safetyItem}>
      <div className={style.header}>
        <h2 className={style.title}>{title}</h2>
        <div className={style.showAll}>{strings.showMore}</div>
      </div>
      {subtitle && <p>{subtitle}</p>}
      {text && <p className={style.text}>{text}</p>}
      {imgSrc && <img className={style.video} src={imgSrc} alt="" />}
      {imgText && <p className={style.videoText}>{imgText}</p>}
      {!hideBorder && <div className={style.border} />}
    </Link>
  )
}
