import React from "react"
import { Link } from "react-router-dom"
import { useLocale } from "../../contexts/LocaleContext"
import { useWeather } from "../../contexts/WeatherContext"
import { svgs } from "../../img"
import { weatherSvgs } from "../../img/weather-symbols"
import { CustomSvgProps } from "../../types/Svg"
import styles from "./Navbar.module.css"

const keyToSymbol: Record<number, (props: CustomSvgProps) => JSX.Element> = {
  1: weatherSvgs.Sun,
  2: weatherSvgs.Sun,
  3: weatherSvgs.HalfClearSky,
  4: weatherSvgs.HalfClearSky,
  5: weatherSvgs.CloudySky,
  6: weatherSvgs.CloudySky,
  7: weatherSvgs.Fog,
  8: weatherSvgs.LightRain,
  9: weatherSvgs.Rain,
  10: weatherSvgs.HeavyRain,
  11: weatherSvgs.Thunder,
  12: weatherSvgs.LightRain,
  13: weatherSvgs.Rain,
  14: weatherSvgs.HeavyRain,
  15: weatherSvgs.LightSnow,
  16: weatherSvgs.Snow,
  17: weatherSvgs.HeavySnow,
  18: weatherSvgs.LightRain,
  19: weatherSvgs.Rain,
  20: weatherSvgs.HeavyRain,
  21: weatherSvgs.Thunder,
  22: weatherSvgs.LightSnow,
  23: weatherSvgs.Snow,
  24: weatherSvgs.HeavySnow,
  25: weatherSvgs.LightSnow,
  26: weatherSvgs.Snow,
  27: weatherSvgs.HeavySnow,
}

export default function Navbar() {
  const { strings } = useLocale()
  const { temperature, windSpeed, weatherSymbolKey } = useWeather()
  const WeatherSymbol = weatherSymbolKey && keyToSymbol[weatherSymbolKey]
  return (
    <nav className={styles.nav}>
      <div className={styles.goboat}>
        <Link to="/home">
          <svgs.GoboatLogo />
        </Link>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.wind}>
            <svgs.Arrow /> {windSpeed} m/s
          </div>
          <div className={styles.city}>
            <svgs.Position /> {strings.copenhagen}
          </div>
        </div>
        <div className={styles.sun}>{WeatherSymbol && <WeatherSymbol />}</div>
        <div className={styles.degrees}>{temperature}°</div>
      </div>
    </nav>
  )
}
