import React from "react"
import { Redirect, Route } from "react-router-dom"
import AllArticles from "./all-screens/AllArticles"
import AllEvents from "./all-screens/AllEvents"
import AllPartnerships from "./all-screens/AllPartnerships"
import AllPois from "./all-screens/AllPois"
import Home from "./Home"
import Map from "./Map"
import Safety from "./Safety"
import DetailEvent from "./detail-screens/DetailEvent"
import DetailPartnership from "./detail-screens/DetailPartnership"
import DetailArticle from "./detail-screens/DetailArticle"
import DetailPoi from "./detail-screens/DetailPoi"
import DetailSafety from "./detail-screens/DetailSafety"

function Main() {
  return (
    <>
      <Route path="/" exact>
        <Redirect to="/home" />
      </Route>
      <Route path="/home">
        <Home />
      </Route>
      {/* TODO: use id to set initial active poi */}
      <Route path="/map/:id?">
        <Map />
      </Route>
      <Route path="/safety" exact>
        <Safety />
      </Route>
      {/* TODO: make one all items component, make it appear with usecontext and portal?, transition possible */}
      <Route path="/partnerships">
        <AllPartnerships />
      </Route>
      <Route path="/pois">
        <AllPois />
      </Route>
      <Route path="/events">
        <AllEvents />
      </Route>
      <Route path="/articles">
        <AllArticles />
      </Route>
      <Route path="/event/:id">
        <DetailEvent />
      </Route>
      <Route path="/partnership/:id">
        <DetailPartnership />
      </Route>
      <Route path="/article/:id">
        <DetailArticle />
      </Route>
      <Route path="/poi/:id">
        <DetailPoi />
      </Route>
      <Route path="/safety/:id">
        <DetailSafety />
      </Route>
    </>
  )
}

export default Main
