import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function InfoSvg({
  color = "#959DAD",
  width = "12px",
  height = "100%",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2ZM1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
        fill={color}
      />
      <rect x="5.5" y="5.5" width="1" height="2.5" fill={color} />
      <circle cx="6" cy="4.5" r="0.5" fill={color} />
    </svg>
  )
}
