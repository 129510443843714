import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function ThunderSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="5"
        y="1"
        width="14"
        height="22"
        fill="black"
      >
        <rect fill="white" x="5" y="1" width="14" height="22" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4V11L17 10L12 20V13L7 14L12 4Z"
        />
      </mask>
      <path
        d="M12 11H10C10 11.5992 10.2686 12.1668 10.732 12.5467C11.1954 12.9266 11.8047 13.0787 12.3922 12.9612L12 11ZM12 4H14C14 3.07244 13.3622 2.26661 12.4595 2.0535C11.5568 1.84039 10.626 2.27594 10.2111 3.10557L12 4ZM17 10L18.7889 10.8944C19.1305 10.2112 19.0531 9.39306 18.5894 8.78601C18.1258 8.17896 17.3568 7.88903 16.6078 8.03884L17 10ZM12 20H10C10 20.9276 10.6378 21.7334 11.5405 21.9465C12.4432 22.1596 13.374 21.7241 13.7889 20.8944L12 20ZM12 13H14C14 12.4008 13.7314 11.8332 13.268 11.4533C12.8046 11.0734 12.1953 10.9213 11.6078 11.0388L12 13ZM7 14L5.21115 13.1056C4.86953 13.7888 4.94692 14.6069 5.41059 15.214C5.87425 15.821 6.6432 16.111 7.39223 15.9612L7 14ZM14 11V4H10V11H14ZM16.6078 8.03884L11.6078 9.03884L12.3922 12.9612L17.3922 11.9612L16.6078 8.03884ZM13.7889 20.8944L18.7889 10.8944L15.2111 9.10557L10.2111 19.1056L13.7889 20.8944ZM10 13V20H14V13H10ZM7.39223 15.9612L12.3922 14.9612L11.6078 11.0388L6.60777 12.0388L7.39223 15.9612ZM10.2111 3.10557L5.21115 13.1056L8.78885 14.8944L13.7889 4.89443L10.2111 3.10557Z"
        fill={color}
        mask="url(#path-1-outside-1)"
      />
    </svg>
  )
}
