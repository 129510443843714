import React from "react"
import classNames from "classnames"
import styles from "./Tag.module.css"
import { Colors, poppingColors } from "../../types/Colors"
import { svgs } from "../../img"
import { useLocale } from "../../contexts/LocaleContext"

export default function Tag({
  icon,
  text,
  background = "light-blue",
}: {
  icon?: React.ReactNode
  text?: string | null
  background?: Colors
}) {
  const longItem = !!text

  const classes = classNames(styles.tag, styles[background], {
    [styles.longItem]: longItem,
  })

  return (
    <div className={classes}>
      <div className={styles.content}>
        {icon}
        {text}
      </div>
    </div>
  )
}

function RestaurantCategory() {
  const { strings } = useLocale()
  return (
    <Tag
      text={strings.restaurant}
      icon={<svgs.Cutlery width="12px" color="white" />}
      background="pink"
    />
  )
}

function EventCategory() {
  const { strings } = useLocale()

  return (
    <Tag
      text={strings.events}
      icon={<svgs.Event width="12px" color="white" />}
      background="green"
    />
  )
}

function AttractionCategory() {
  const { strings } = useLocale()

  return (
    <Tag
      text={strings.attraction}
      icon={<svgs.Eye width="12px" color="white" />}
      background="purple"
    />
  )
}

function SwimzoneCategory() {
  const { strings } = useLocale()

  return (
    <Tag
      text={strings.swimzone}
      icon={<svgs.Swimzone width="16px" color="white" />}
      background="blue"
    />
  )
}

function BaseCategory() {
  const { strings } = useLocale()

  return (
    <Tag
      text={strings.base}
      icon={<svgs.Base width="12px" color="white" />}
      background="goboat-blue"
    />
  )
}

function ShoppingCategory() {
  const { strings } = useLocale()

  return (
    <Tag
      text={strings.shopping}
      icon={<svgs.Shopping width="12px" color="white" />}
      background="yellow"
    />
  )
}

// TODO: use ids instead of names?
// (the names for a category tag must be the same as the name in sanity)
// Get the tag by using getCategoryTag function below !!
export type CategoryTag =
  | "Restaurant"
  | "Event"
  | "Seværdighed"
  | "Badezone"
  | "GoBoat Base"
  | "Shopping"
export const categorys: Record<CategoryTag, React.ReactNode> = {
  Restaurant: <RestaurantCategory key="Restaurant" />,
  Event: <EventCategory key="Event" />,
  Seværdighed: <AttractionCategory key="Attraction" />,
  Badezone: <SwimzoneCategory key="Swimzone" />,
  "GoBoat Base": <BaseCategory key="GoBoat Base" />,
  Shopping: <ShoppingCategory key="Shopping" />,
}

/* eslint no-bitwise: 0 */
/* eslint operator-assignment: 0 */
/* eslint no-underscore-dangle: 0 */
function getHashCode(text: string) {
  let hash = 0
  if (text.length === 0) {
    return hash
  }
  for (let i = 0; i < text.length; i += 1) {
    const char = text.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}

export function getCategoryTag(item: any) {
  const name: string | undefined = item?.category?.name
  if (!name) return null
  if (name in categorys) return categorys[name as CategoryTag]

  const colorIndex = Math.abs(getHashCode(name)) % poppingColors.length
  const color = poppingColors[colorIndex]

  return <Tag key={item._id} text={name} background={color} />
}

export function getFormatTag(item: any) {
  const name: string | undefined = item?.format?.name
  if (!name) return null
  return <Tag key={item._id} text={name} background="goboat-blue" />
}

// the tags with type of "tag"
export function getTagTags(item: any) {
  const { tags }: { tags: any[] | undefined } = item || {}
  if (!tags) return null
  return tags.map((tag) => {
    return <Tag key={tag?.name} text={tag?.name} />
  })
}
