import React from "react"
import { useLocale } from "../contexts/LocaleContext"
import Tag from "../components/tag/Tag"

const capitalise = (s: string) => {
  if (typeof s !== "string") return null
  return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
}

/* eslint-disable no-underscore-dangle */
const useItemType = () => {
  const { strings } = useLocale()

  const getItemType = (item: any) => {
    if (!item._type) return null
    if (item._type === "safetySection") {
      return <Tag key={item?._type} text={strings.safety} />
    }

    if (item._type === "poi") {
      return <Tag key={item?._type} text={strings.poi} />
    }

    if (item._type === "article") {
      return <Tag key={item?._type} text={strings.article} />
    }
    return <Tag key={item?._type} text={capitalise(item?._type)} />
  }

  return getItemType
}

export default useItemType
