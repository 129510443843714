import React from "react"
import { Link } from "react-router-dom"
import styles from "./BigSliderItem.module.css"

// TODO: add image placeholder, for smallSlider to

export default function BigSliderItem({
  imgSrc,
  title,
  subtitle,
  to,
  tags,
}: {
  imgSrc: string
  title: string
  subtitle?: string
  to?: string
  tags?: React.ReactNode
}) {
  const content = (
    <>
      <div className={styles.imgWrap}>
        <img src={imgSrc} alt="img" className={styles.img} />
        <div className={styles.tags}>{tags}</div>
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>{subtitle}</p>
    </>
  )
  return to ? (
    <Link to={to} className={styles.item}>
      {content}
    </Link>
  ) : (
    <div className={styles.item}>{content}</div>
  )
}
