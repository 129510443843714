import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function ArrowSvg({
  color = "white",
  height = "100%",
  width = "16px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33329 2.66669H2.66663V13.3334H13.3333V8.66669"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinejoin="round"
      />
      <path
        d="M8.66671 2.66669H13.3334M13.3334 2.66669V7.33335M13.3334 2.66669L7.33337 8.66669"
        stroke={color}
        strokeWidth="1.33333"
      />
    </svg>
  )
}
