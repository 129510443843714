import React, { useEffect, useMemo, useState, useRef } from "react"
import { Marker } from "react-map-gl"
import { Link, useHistory, useParams } from "react-router-dom"
import Screen from "./Screen"
import Map from "../components/map/Map"
import Coordinate from "../types/Coordinate"
import { useCMS } from "../contexts/SanityContext"
import TaggedItem from "../components/tagged-item/TaggedItem"
import { urlFor } from "../util/sanity"
import { CategoryTag, getCategoryTag } from "../components/tag/Tag"
import { resolveMapPin } from "../img/map-pins"
import styles from "./Map.module.css"
import useSubtitle from "../hooks/useSubtitle"
import { ReadMoreButton } from "../components/buttons/Button"
import { svgs } from "../img"
import { FilterComponent } from "../components/filter/Filter"
import globalstyle from "../globalstyles.module.css"

/* eslint no-underscore-dangle: 0 */
const defaultPosition = Coordinate.create(12.591259737810718, 55.67965259731868)

export default function MapScreen() {
  const { pois, events } = useCMS()
  const items = pois && events && [...pois, ...events]
  const [mapItems, setMapItems] = useState(items)
  const pinsFilters = useRef<{ id: string; name: string }[]>([])
  const [tagIds, setTagIds] = useState<string[]>([])
  const [activeId, setActiveId] = useState("")
  const [activeItem, setActiveItem] = useState<any | null>(null)
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const getSubtitle = useSubtitle()

  useEffect(() => {
    setActiveId(id)
  }, [id])

  useEffect(() => {
    setMapItems(items)
  }, [pois, events])

  useEffect(() => {
    if (tagIds.length !== 0) {
      const result = items?.filter((post: { category: { _id: string } }) => {
        if (tagIds.includes(post.category._id)) {
          return true
        }
        return null
      })
      setMapItems(result)
    } else {
      setMapItems(items)
    }
  }, [tagIds])

  const markers = useMemo(() => {
    return mapItems?.map((item) => {
      if (item.category != null) {
        if (
          pinsFilters.current.find((v) => v.id === item.category._id) == null
        ) {
          const key = { id: item.category._id, name: item.category.name }
          pinsFilters.current = [...pinsFilters.current, key]
        }
      }
      const Pin = resolveMapPin(item.category?.name as CategoryTag)
      return item.location == null ? null : (
        <Marker
          latitude={item.location.lat}
          longitude={item.location.lng}
          key={item._id}
          offsetLeft={-22}
          offsetTop={-38}
          captureDrag={false}
          captureClick
        >
          <Pin
            style={{ transform: "scale(0.9)" }}
            active={item._id === activeId}
            star={item.recommended}
            onClick={() => history.push(`/map/${item._id}`)}
          />
        </Marker>
      )
    })
  }, [mapItems, activeId])

  // TODO: just filter existing stuff?
  useEffect(() => {
    const candidate = mapItems?.find((item) => item._id === activeId)
    if (candidate != null) {
      setActiveItem(candidate)
    }
    if (candidate === undefined) {
      setActiveItem(null)
    }
  }, [activeId, mapItems])

  const resetActive = () => {
    setActiveItem(null)
    history.push("/map")
  }

  let activePoint
  let mapPosition = defaultPosition

  const closeButton = (
    <button
      onClick={() => resetActive()}
      className={styles.closeButton}
      type="button"
    >
      <svgs.X color="#2E3D5C" width="24px" />
    </button>
  )

  if (activeItem != null) {
    activePoint = (
      <div className={styles.itemWrapper}>
        {closeButton}
        <TaggedItem
          title={activeItem.title}
          imgSrc={urlFor(activeItem.hero).size(320, 320).url()!}
          subtitle={getSubtitle(activeItem)}
          star={activeItem.recommended}
          hideBorder
          tags={[getCategoryTag(activeItem)]}
          starHack
        />
        <Link to={`/${activeItem._type}/${activeItem._id}`}>
          <ReadMoreButton />
        </Link>
      </div>
    )

    mapPosition = Coordinate.create(
      activeItem.location.lng,
      activeItem.location.lat
    )
  }

  return (
    <Screen>
      <FilterComponent
        className={globalstyle.filter}
        filters={pinsFilters.current}
        callback={setTagIds}
      />
      <Map
        activeItem={activeItem}
        point={mapPosition}
        onClick={() => resetActive()}
      >
        {markers}
      </Map>
      {activePoint}
    </Screen>
  )
}
