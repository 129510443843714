import SunSvg from "./SunSvg"
import HalfClearSkySvg from "./HalfClearSkySvg"
import CloudySkySvg from "./CloudySkySvg"
import FogSvg from "./FogSvg"
import LightRainSvg from "./LightRainSvg"
import RainSvg from "./RainSvg"
import HeavyRainSvg from "./HeavyRainSvg"
import ThunderSvg from "./ThunderSvg"
import LightSnowSvg from "./LightSnowSvg"
import SnowSvg from "./SnowSvg"
import HeavySnowSvg from "./HeavySnowSvg"

/* eslint-disable import/prefer-default-export */
export const weatherSvgs = {
  Sun: SunSvg,
  HalfClearSky: HalfClearSkySvg,
  CloudySky: CloudySkySvg,
  Fog: FogSvg,
  LightRain: LightRainSvg,
  Rain: RainSvg,
  HeavyRain: HeavyRainSvg,
  Thunder: ThunderSvg,
  LightSnow: LightSnowSvg,
  Snow: SnowSvg,
  HeavySnow: HeavySnowSvg,
}
