import React from "react"
import { svgs } from ".."
import { MapPinsProps } from "../../types/Svg"
import cssStyle from "./Pins.module.css"

export default function ShoppingPin({
  style,
  className,
  active = false,
  star,
  onClick,
}: MapPinsProps) {
  const pin = (
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <path
        d="M32 16.4C32 25.4575 20 40 16 40C12 40 0 25.4575 0 16.4C0 7.34253 7.1634 0 16 0C24.8366 0 32 7.34253 32 16.4Z"
        fill={active ? "white" : "var(--yellow)"}
      />
      <path
        d="M19.1 15.9C18.5 15.9 18.1 15.5 18.1 14.9V11.1C18.1 10.4 17.2 9.7 16.1 9.7C15 9.7 14.1 10.3 14.1 11.1V14.9C14.1 15.5 13.7 15.9 13.1 15.9C12.5 15.9 12.1 15.5 12.1 14.9V11.1C12.1 9.2 13.9 7.7 16.1 7.7C18.3 7.7 20.1 9.2 20.1 11.1V14.9C20.1 15.5 19.7 15.9 19.1 15.9Z"
        fill={active ? "var(--yellow)" : "white"}
      />
      <path
        d="M21.6 26.2H10.4C8.60001 26.2 7.10001 24.7 7.10001 22.9V13.9C7.10001 12.8 8.00001 11.9 9.10001 11.9H23C24.1 11.9 25 12.8 25 13.9V22.9C25 24.7 23.5 26.2 21.6 26.2ZM23 13.9H9.00001V22.9C9.00001 23.6 9.60001 24.2 10.3 24.2H21.6C22.3 24.2 22.9 23.6 22.9 22.9V13.9H23Z"
        fill={active ? "var(--yellow)" : "white"}
      />
    </svg>
  )

  if (star) {
    return (
      <div style={{ position: "relative" }}>
        <svgs.Star className={cssStyle.pinStar} height="16px" width="16px" />
        {pin}
      </div>
    )
  }

  return pin
}
