/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { DateTime } from "luxon"
import { useCMS } from "../../contexts/SanityContext"
import { urlFor } from "../../util/sanity"
import SmallSliderItem from "../sliderItems/SmallSliderItem"

export default function Events() {
  const { events } = useCMS()

  const eventsUi = events
    ?.filter((v, i) => i < 5)
    .map((event: any) => (
      <SmallSliderItem
        key={event._id}
        title={event.title}
        imgSrc={urlFor(event.hero).size(500, 249).url()!}
        subtitle={DateTime.fromISO(event.datetime).toFormat("D - HH:mm")}
        to={`/event/${event._id}`}
      />
    ))

  return <>{eventsUi}</>
}
