import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function MapSvg({
  color = "#2E3D5C",
  width = "17px",
  height = "100%",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49996 2.66665C6.3084 2.66665 4.49996 4.49937 4.49996 6.79998C4.49996 7.33225 4.68018 8.01695 5.0101 8.77828C5.33563 9.5295 5.78616 10.3081 6.27654 11.0136C6.76779 11.7203 7.28598 12.3349 7.73793 12.7646C7.96479 12.9803 8.1613 13.1362 8.31775 13.234C8.41515 13.2948 8.47425 13.3187 8.49996 13.328C8.52567 13.3187 8.58477 13.2948 8.68217 13.234C8.83861 13.1362 9.03513 12.9803 9.26198 12.7646C9.71394 12.3349 10.2321 11.7203 10.7234 11.0136C11.2138 10.3081 11.6643 9.5295 11.9898 8.77828C12.3197 8.01695 12.5 7.33225 12.5 6.79998C12.5 4.49937 10.6915 2.66665 8.49996 2.66665ZM3.16663 6.79998C3.16663 3.79865 5.53686 1.33331 8.49996 1.33331C11.4631 1.33331 13.8333 3.79865 13.8333 6.79998C13.8333 7.5932 13.576 8.47123 13.2132 9.30843C12.8461 10.1557 12.3487 11.0115 11.8182 11.7746C11.2886 12.5365 10.7131 13.2248 10.1806 13.731C9.91531 13.9832 9.64698 14.2033 9.38871 14.3647C9.14949 14.5142 8.83604 14.6666 8.49996 14.6666C8.16387 14.6666 7.85043 14.5142 7.61121 14.3647C7.35294 14.2033 7.08461 13.9832 6.81928 13.731C6.28685 13.2248 5.7113 12.5365 5.18171 11.7746C4.65126 11.0115 4.15387 10.1557 3.78669 9.30843C3.4239 8.47123 3.16663 7.5932 3.16663 6.79998Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 7.33335C8.86819 7.33335 9.16667 7.03488 9.16667 6.66669C9.16667 6.2985 8.86819 6.00002 8.5 6.00002C8.13181 6.00002 7.83333 6.2985 7.83333 6.66669C7.83333 7.03488 8.13181 7.33335 8.5 7.33335ZM8.5 8.66669C9.60457 8.66669 10.5 7.77126 10.5 6.66669C10.5 5.56212 9.60457 4.66669 8.5 4.66669C7.39543 4.66669 6.5 5.56212 6.5 6.66669C6.5 7.77126 7.39543 8.66669 8.5 8.66669Z"
        fill={color}
      />
    </svg>
  )
}
