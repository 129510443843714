import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function LightRainSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92108 10.0789L10.1285 10.8777L10.4482 9.46565C10.9024 7.45859 12.6372 6 14.6667 6C17.0311 6 19 7.98549 19 10.5C19 13.0145 17.0311 15 14.6667 15H7.55556C6.17294 15 5 13.8353 5 12.3333C5 10.8313 6.17294 9.66667 7.55556 9.66667C8.0577 9.66667 8.52497 9.81685 8.92108 10.0789Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M15 18V18.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M12 18V18.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M9 18V18.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}
