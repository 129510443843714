import React, { useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"
import Screen from "../Screen"
import TaggedItem from "../../components/tagged-item/TaggedItem"
import { getTagTags } from "../../components/tag/Tag"
import { ExternalButton } from "../../components/buttons/Button"
import { urlFor } from "../../util/sanity"
import { useCMS } from "../../contexts/SanityContext"
import globalstyle from "../../globalstyles.module.css"
import { getFirstParagraph } from "../../logic/blockContent"
import { FilterComponent } from "../../components/filter/Filter"
import { useLocale } from "../../contexts/LocaleContext"

/* eslint no-underscore-dangle: 0 */
export default function AllArticles() {
  const { articles } = useCMS()
  const articleFilters = useRef<{ id: string; name: string }[]>([])
  const [tagIds, setTagIds] = useState<string[]>([])
  const [filteredArticles, setFilteredArticles] = useState<any>(articles)

  useEffect(() => {
    if (tagIds.length !== 0) {
      const result = articles?.filter(
        (post: { format: { _id: string }; tags: string | any[] }) => {
          for (let i = 0; i < tagIds.length; i += 1) {
            if (tagIds[i] === post.format._id) {
              return true
            }

            for (let x = 0; x < post?.tags?.length; x += 1) {
              if (tagIds.includes(post.tags[x]._id)) {
                return true
              }
            }
          }
          return false
        }
      )
      setFilteredArticles(result)
    } else {
      setFilteredArticles(articles)
    }
  }, [tagIds, articles])

  const articlesUi = filteredArticles?.map((article: any) => {
    article.tags?.map((tag: any) => {
      if (articleFilters.current.find((v) => v.id === tag._id) == null) {
        const obj = { id: tag._id, name: tag.name }
        articleFilters.current = [...articleFilters.current, obj]
      }
      return null
    })

    return (
      <TaggedItem
        key={article._id}
        imgSrc={urlFor(article.hero).size(320, 320).url()!}
        title={article.headline}
        subtitle={DateTime.fromISO(article._createdAt).toFormat("D")}
        tags={[getTagTags(article)]}
        hideBorder
        text={`${getFirstParagraph(article.body)?.substring(0, 148)}${
          getFirstParagraph(article.body).length > 148 ? "..." : ""
        }`}
        button={
          <div className={globalstyle.buttonWrapper}>
            {article?.cta?.text && article?.cta?.url && (
              <ExternalButton
                text={article?.cta.text}
                onClick={(e) => {
                  e.preventDefault()
                  window.open(
                    article.cta.url,
                    "_blank",
                    "noopener noreferrer nofollow"
                  )
                }}
              />
            )}
          </div>
        }
        className={globalstyle.taggedItem}
        star={article.recommended}
        to={`/article/${article._id}`}
        hideInfo
      />
    )
  })

  const { strings } = useLocale()

  return (
    <Screen>
      <div className={globalstyle.heading}>
        <h1 className={globalstyle.heading}>{strings.articles}</h1>
        <FilterComponent
          className={globalstyle.filter}
          filters={articleFilters.current}
          callback={setTagIds}
        />
      </div>
      {articlesUi}
    </Screen>
  )
}
