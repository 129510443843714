import { CategoryTag } from "../../components/tag/Tag"
import { MapPinsProps } from "../../types/Svg"
import RestaurantPin from "./RestaurantPin"
import EventPin from "./EventPin"
import AttractionPin from "./AttractionPin"
import BasePin from "./BasePin"
import SwimzonePin from "./SwimZonePin"
import ShoppingPin from "./ShoppingPin"
/* eslint-disable import/prefer-default-export */

const mapPins: Record<string, (props: MapPinsProps) => JSX.Element> = {
  Restaurant: RestaurantPin,
  Event: EventPin,
  Seværdighed: AttractionPin,
  Badezone: SwimzonePin,
  "GoBoat Base": BasePin,
  Shopping: ShoppingPin,
}

export function resolveMapPin(
  tag: CategoryTag
): (props: MapPinsProps) => JSX.Element {
  return mapPins[tag] ?? AttractionPin
}
