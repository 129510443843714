import React from "react"
import { DateTime } from "luxon"
import Screen from "../Screen"
import TaggedItem from "../../components/tagged-item/TaggedItem"
import { getCategoryTag } from "../../components/tag/Tag"
import { ExternalButton } from "../../components/buttons/Button"
import { urlFor } from "../../util/sanity"
import { getFirstParagraph } from "../../logic/blockContent"
import { useCMS } from "../../contexts/SanityContext"
import globalstyle from "../../globalstyles.module.css"
import { useLocale } from "../../contexts/LocaleContext"

/* eslint no-underscore-dangle: 0 */
export default function AllEvents() {
  const { events } = useCMS()

  const eventsUi = events?.map((event: any) => (
    <TaggedItem
      key={event._id}
      imgSrc={urlFor(event.hero).size(320, 320).url()!}
      title={event.title}
      subtitle={DateTime.fromISO(event.datetime).toFormat("D - HH:mm")}
      tags={[getCategoryTag(event)]}
      hideBorder
      button={
        <div className={globalstyle.buttonWrapper}>
          {event.cta && (
            <ExternalButton
              text={event.cta.text}
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                window.open(
                  event.cta.url,
                  "_blank",
                  "noopener noreferrer nofollow"
                )
              }}
            />
          )}
        </div>
      }
      text={`${getFirstParagraph(event.body)?.substring(0, 148)}${
        getFirstParagraph(event.body).length > 148 ? "..." : ""
      }`}
      className={globalstyle.taggedItem}
      to={`/event/${event._id}`}
      star={event.recommended}
    />
  ))

  const { strings } = useLocale()
  return (
    <Screen>
      <div className={globalstyle.heading}>
        <h1>{strings.events}</h1>
        {eventsUi}
      </div>
    </Screen>
  )
}
