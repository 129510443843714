import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function MailSvg({
  color = "#2E3D5C",
  height = "100%",
  width = "16px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 4.66683H12.6667V5.58811L8.29811 7.77239C8.11042 7.86624 7.88951 7.86624 7.70182 7.77239L3.33333 5.58815V4.66683ZM3.33333 7.07886L3.33333 11.3335H12.6667V7.07883L8.89439 8.96496C8.33134 9.24649 7.66859 9.24649 7.10554 8.96496L3.33333 7.07886ZM2 4.66683C2 3.93045 2.59695 3.3335 3.33333 3.3335H12.6667C13.403 3.3335 14 3.93045 14 4.66683V11.3335C14 12.0699 13.403 12.6668 12.6667 12.6668H3.33333C2.59695 12.6668 2 12.0699 2 11.3335V4.66683Z"
        fill={color}
      />
    </svg>
  )
}
