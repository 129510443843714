import React, { useRef, useLayoutEffect } from "react"
import classNames from "classnames"
import styles from "./Slider.module.css"

export default function Slider({
  className,
  paddingRight = "16px",
  padding = "16px 0px 0px 16px",
  children,
  id,
}: {
  className?: string
  paddingRight?: string
  padding?: string
  children?: React.ReactNode
  id?: string
}) {
  const classes = classNames(styles.slider, className)
  const slider = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const lastChild = slider.current?.lastChild as HTMLElement | null
    if (lastChild) lastChild.style.paddingRight = paddingRight
  })

  return (
    <div ref={slider} className={classes} style={{ padding }} id={id}>
      {children}
    </div>
  )
}
