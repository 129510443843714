import React from "react"
import { useHistory } from "react-router-dom"
import classNames from "classnames"
import { Colors } from "../../types/Colors"
import { svgs } from "../../img"
import style from "./Button.module.css"
import { useLocale } from "../../contexts/LocaleContext"

export default function Button({
  background = "light-blue",
  onClick,
  className,
  children,
}: {
  background?: Colors
  onClick?: (e: any) => void
  className?: string
  children?: React.ReactNode
}) {
  const classes = classNames(
    style.button,
    style[background],
    style.readmore,
    className
  )

  return (
    <button
      type="button"
      onClick={onClick && ((e) => onClick(e))}
      className={classes}
    >
      {children}
    </button>
  )
}

export function ViewOnMapButton({ id }: { id: string }) {
  const history = useHistory()
  const { strings } = useLocale()

  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        history.push(`/map/${id}`)
      }}
    >
      <svgs.MapPin />
      {strings.viewOnMap}
    </Button>
  )
}

export function ExternalButton({
  text,
  onClick,
  className,
}: {
  text: string
  onClick?: (e: any) => void
  className?: string
}) {
  return (
    <Button
      className={className}
      onClick={onClick && ((e) => onClick(e))}
      background="goboat-blue"
    >
      <svgs.External />
      {text}
    </Button>
  )
}

export function ReadMoreButton() {
  const { strings } = useLocale()

  return (
    <Button background="goboat-blue" className={style.readMoreButtonMap}>
      {strings.readMore}
    </Button>
  )
}
