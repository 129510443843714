import React from "react"
import Screen from "../Screen"
import TaggedItem from "../../components/tagged-item/TaggedItem"
import { getCategoryTag } from "../../components/tag/Tag"
import { ViewOnMapButton } from "../../components/buttons/Button"
import { urlFor } from "../../util/sanity"
import { useCMS } from "../../contexts/SanityContext"
import { useLocale } from "../../contexts/LocaleContext"
import globalstyle from "../../globalstyles.module.css"

export default function AllPartnerships() {
  const { strings } = useLocale()
  const { partnerships } = useCMS()
  /* eslint no-underscore-dangle: 0 */

  // Get by mapping over data from sanity and using a funciton
  /* const tags = [
    <Tag
      text="resturant"
      icon={<svgs.Home width="12px" color="white" />}
      background="pink"
    />,
    <Tag icon={<svgs.ChangeDiaper />} />,
    <Tag icon={<svgs.UnionToilet />} />,
  ] */

  const partnershipsUi = partnerships?.map((partnership: any) => (
    <TaggedItem
      key={partnership._id}
      imgSrc={urlFor(partnership.hero).size(320, 320).url()!}
      title={partnership.headline}
      subtitle={
        partnership.hours
          ? partnership.hours?.replace(/\n/g, ", ")
          : strings.timeNotAvailable
      }
      tags={[getCategoryTag(partnership)]}
      hideBorder
      text={partnership.description}
      button={
        <div className={globalstyle.buttonWrapper}>
          {partnership.poi && <ViewOnMapButton id={partnership.poi._ref} />}
        </div>
      }
      star={partnership.recommended}
      className={globalstyle.taggedItem}
      to={`/partnership/${partnership._id}`}
    />
  ))

  return (
    <Screen>
      <div className={globalstyle.heading}>
        <h1>{strings.partnerships}</h1>
        {partnershipsUi}
      </div>
    </Screen>
  )
}
