import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function ArrowSvg({
  color = "#F1F3F6",
  height = "100%",
  width = "8px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70735 7.3283L0.671814 7.3283L0.671814 3.29276H1.67181L1.67181 5.62119L6.47512 0.817889L7.18222 1.525L2.37892 6.3283L4.70735 6.3283L4.70735 7.3283Z"
        fill={color}
      />
    </svg>
  )
}
