import React from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"
import { svgs } from "../../img"
import styles from "./Actionbar.module.css"
import { useLocale } from "../../contexts/LocaleContext"

export function ActionbarItem({
  icon,
  name,
  urlKey,
}: {
  icon: React.ReactNode
  name: string
  urlKey: string
}) {
  const { pathname } = useLocation()

  const nameClasses = classNames(styles.name, {
    [styles.active]: pathname.includes(`/${urlKey}`),
  })

  return (
    <Link to={`/${urlKey}`}>
      <div className={styles.item}>
        <div>
          <span className={styles.iconWrapper}>{icon}</span>
        </div>
        <span className={nameClasses}>{name}</span>
      </div>
    </Link>
  )
}

export default function Actionbar({ items }: { items?: React.ReactNode[] }) {
  const { pathname } = useLocation()
  const { strings } = useLocale()

  const svgColor = (activePath: string) => {
    return pathname.includes(`/${activePath}`) ? "#2e3d5c" : "#959dad"
  }

  return (
    <nav className={styles.actionbar}>
      {items != null
        ? items
        : [
            <ActionbarItem
              key="home"
              urlKey="home"
              name={strings.home}
              icon={<svgs.Home color={svgColor("home")} />}
            />,
            <ActionbarItem
              key="map"
              urlKey="map"
              name={strings.map}
              icon={<svgs.Map color={svgColor("map")} />}
            />,
            <ActionbarItem
              key="safety"
              urlKey="safety"
              name={strings.safety}
              icon={<svgs.Safety color={svgColor("safety")} />}
            />,
          ]}
    </nav>
  )
}
