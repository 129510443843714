import React from "react"
import { Link } from "react-router-dom"
import { useLocale } from "../../contexts/LocaleContext"
import style from "./ItemsContainer.module.css"

export default function ItemsContainer({
  title,
  children,
  hideBorder,
  pathToAll,
}: {
  title: string
  pathToAll: string
  children?: React.ReactNode
  hideBorder?: boolean
}) {
  const { strings } = useLocale()
  return (
    <>
      <Link to={pathToAll}>
        <div className={style.header}>
          <h2 className={style.title}>{title}</h2>
          <div className={style.showAll}>{strings.showAll}</div>
        </div>
      </Link>

      <div className={style.items}>{children}</div>
      {!hideBorder && <div className={style.border} />}
    </>
  )
}
