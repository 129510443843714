import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function HomeSvg({
  color = "#2E3D5C",
  height = "100%",
  width = "30px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10V20H10V15H14V20H19V10"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4 11C6.66667 8.66667 12 4 12 4L20 11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
