import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function XSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 5L19 19" stroke={color} strokeWidth="2" />
      <path d="M19 5L5 19" stroke={color} strokeWidth="2" />
    </svg>
  )
}
