import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styles from "../../components/tag/Tag.module.css"

import Faq from "../../components/faq/Faq"
import sanityClient, { urlFor } from "../../util/sanity"
import Screen from "../Screen"
import BlockContent from "../BlockContent"
import style from "./DetailArticle.module.css"
import { ExternalButton } from "../../components/buttons/Button"
import { getTagTags } from "../../components/tag/Tag"

export default function DetailArticle() {
  const { id } = useParams<{ id: string }>()
  const [article, setArticle] = useState<any>(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_id == "${id}"]{
          ...,
          format->,
          tags[]->,
          _type == "article" => {
            "format": article->{"name": format->name},
            "hours": article->hours
          },
        }`
      )
      .then((data) => setArticle(data[0]))
      .catch()
  }, [])

  const faq = article?.faq?.map((faqObj: any) => {
    return {
      question: faqObj.questionText,
      answer: faqObj.answerText,
    }
  })

  return (
    <Screen>
      <div className={style.container}>
        {article?.headline && (
          <h1 className={style.subhead}>{article.headline}</h1>
        )}

        <div className={styles.tag}>{[getTagTags(article)]}</div>

        <div className={style.imgWrapper}>
          {article?.hero != null ? (
            <>
              <img
                src={urlFor(article.hero).size(764, 446).url()!}
                className={style.img}
                alt=""
              />
            </>
          ) : (
            <div className={style.img} style={{ background: "grey" }} />
          )}
        </div>

        {article?.body != null && (
          <BlockContent
            blocks={article.body}
            className={`${style.iconBottom} ${style.blockContent}`}
          />
        )}

        {article?.faq?.length > 0 && <Faq content={faq} />}

        {article?.cta?.text && article?.cta?.url && (
          <ExternalButton
            text={article?.cta.text}
            onClick={(e) => {
              e.preventDefault()
              window.open(
                article.cta.url,
                "_blank",
                "noopener noreferrer nofollow"
              )
            }}
          />
        )}
      </div>
    </Screen>
  )
}
