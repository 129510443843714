import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function HalfClearSkySvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 15C22 17.7614 19.7614 20 17 20H10.3333C8.49238 20 7 18.5076 7 16.6667C7 14.8257 8.49238 13.3333 10.3333 13.3333C10.9953 13.3333 11.6121 13.5263 12.1308 13.859C12.647 11.6474 14.6311 10 17 10C19.7614 10 22 12.2386 22 15Z"
        />
      </mask>
      <path
        d="M12.1308 13.859L11.0509 15.5424L13.4347 17.0716L14.0784 14.3136L12.1308 13.859ZM17 22C20.866 22 24 18.866 24 15H20C20 16.6569 18.6569 18 17 18V22ZM17 18H10.3333V22H17V18ZM5 16.6667C5 19.6122 7.38781 22 10.3333 22V18C9.59695 18 9 17.403 9 16.6667H5ZM10.3333 11.3333C7.38781 11.3333 5 13.7211 5 16.6667H9C9 15.9303 9.59695 15.3333 10.3333 15.3333V11.3333ZM13.2107 12.1756C12.3795 11.6424 11.3894 11.3333 10.3333 11.3333V15.3333C10.6011 15.3333 10.8448 15.4102 11.0509 15.5424L13.2107 12.1756ZM17 8C13.681 8 10.9059 10.308 10.1831 13.4044L14.0784 14.3136C14.3881 12.9868 15.5812 12 17 12V8ZM24 15C24 11.134 20.866 8 17 8V12C18.6569 12 20 13.3431 20 15H24Z"
        fill={color}
        mask="url(#path-1-inside-1)"
      />
      <path
        d="M11.5908 14.7007L12.7827 15.4653L13.1046 14.0863C13.5176 12.3171 15.1062 11 17 11C19.2091 11 21 12.7909 21 15C21 17.2091 19.2091 19 17 19H10.3333C9.04467 19 8 17.9553 8 16.6667C8 15.378 9.04467 14.3333 10.3333 14.3333C10.7982 14.3333 11.2285 14.4682 11.5908 14.7007Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M12 3V6M5 13H2M4.92898 5.92893L7.0503 8.05025M19.0711 5.92893L16.9498 8.05025"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2848 10.2883C12.8954 10.1034 12.4598 10 12 10C10.5028 10 9.26175 11.0968 9.03649 12.5307C8.2647 12.7725 7.58581 13.2243 7.06678 13.8192C7.02285 13.5527 7 13.279 7 13C7 10.2386 9.23858 8 12 8C13.2648 8 14.4199 8.46963 15.3005 9.24403C14.5586 9.46272 13.8766 9.82093 13.2848 10.2883Z"
        fill={color}
      />
    </svg>
  )
}
