import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function HeavyRainSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92108 6.0789L10.1285 6.87772L10.4482 5.46565C10.9024 3.45859 12.6372 2 14.6667 2C17.0311 2 19 3.98549 19 6.5C19 9.01451 17.0311 11 14.6667 11H7.55556C6.17294 11 5 9.83532 5 8.33333C5 6.83135 6.17294 5.66667 7.55556 5.66667C8.0577 5.66667 8.52497 5.81685 8.92108 6.0789Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 15V14H6.5V15V21V22H8.5V21V15ZM11.5 15V14H9.5V15V21V22H11.5V21V15ZM14.5 14V15V21V22H12.5V21V15V14H14.5ZM17.5 15V14H15.5V15V21V22H17.5V21V15Z"
        fill={color}
      />
    </svg>
  )
}
