class Coordinate {
  longitude: number

  latitude: number

  static toFeature(coordLike: {
    latitude: number
    longitude: number
  }): GeoJSON.Feature {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coordLike.longitude, coordLike.latitude],
      },
      properties: {},
    }
  }

  static fromArray(array: [number, number]) {
    return new Coordinate(array[0], array[1])
  }

  static create(longitude: number, latitude: number) {
    return new Coordinate(longitude, latitude)
  }

  private constructor(longitude: number, latitude: number) {
    this.longitude = longitude
    this.latitude = latitude
  }

  toFeature(): GeoJSON.Feature {
    return Coordinate.toFeature(this)
  }
}

export default Coordinate
