import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function EyeSvg({
  color = "#2E3D5C",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 8H20" stroke={color} strokeWidth="2" />
      <path d="M7 12H17" stroke={color} strokeWidth="2" />
      <path d="M10 16H14" stroke={color} strokeWidth="2" />
    </svg>
  )
}
