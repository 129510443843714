import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function EventSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4H5C3.89543 4 3 4.89543 3 6V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V6C21 4.89543 20.1046 4 19 4H17C17 3.44772 16.5523 3 16 3C15.4477 3 15 3.44772 15 4H9ZM16 7C15.4477 7 15 6.55228 15 6H9C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6H5L5 18H19V6H17C17 6.55228 16.5523 7 16 7ZM12.3827 8.50186C12.3169 8.34902 12.1664 8.25 12 8.25C11.8336 8.25 11.6831 8.34902 11.6173 8.50186L10.7012 10.6291L8.39505 10.843C8.22935 10.8583 8.08868 10.9708 8.03726 11.1291C7.98583 11.2874 8.03351 11.4611 8.15853 11.5709L9.89855 13.0995L9.38933 15.3589C9.35274 15.5212 9.41625 15.6898 9.55089 15.7876C9.68552 15.8854 9.86544 15.8937 10.0085 15.8087L12 14.6263L13.9915 15.8087C14.1345 15.8937 14.3145 15.8854 14.4491 15.7876C14.5837 15.6898 14.6472 15.5212 14.6107 15.3589L14.1014 13.0995L15.8414 11.5709C15.9665 11.4611 16.0142 11.2874 15.9627 11.1291C15.9113 10.9708 15.7706 10.8583 15.6049 10.843L13.2988 10.6291L12.3827 8.50186Z"
        fill={color}
      />
    </svg>
  )
}
