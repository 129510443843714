import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function SafetySvg({
  color = "#959DAD",
  height = "100%",
  width = "30px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.06189 11C4.51314 7.38128 7.38128 4.51314 11 4.06189L11 7.10002C9.04087 7.4977 7.4977 9.04087 7.10002 11L4.06189 11ZM4.06189 13C4.51314 16.6187 7.38128 19.4869 11 19.9381L11 16.9C9.04087 16.5023 7.4977 14.9591 7.10002 13L4.06189 13ZM13 19.9381C16.6187 19.4869 19.4869 16.6187 19.9381 13L16.9 13C16.5023 14.9591 14.9591 16.5023 13 16.9L13 19.9381ZM16.9 11L19.9381 11C19.4869 7.38128 16.6187 4.51314 13 4.06189L13 7.10002C14.9591 7.4977 16.5023 9.04087 16.9 11ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
        fill={color}
      />
    </svg>
  )
}
