import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function LightSnowSvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92108 9.0789L10.1285 9.87772L10.4482 8.46565C10.9024 6.45859 12.6372 5 14.6667 5C17.0311 5 19 6.98549 19 9.5C19 12.0145 17.0311 14 14.6667 14H7.55556C6.17294 14 5 12.8353 5 11.3333C5 9.83135 6.17294 8.66667 7.55556 8.66667C8.0577 8.66667 8.52497 8.81685 8.92108 9.0789Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M10 17.5L8.5 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 17.5L14 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M10 19L8.5 17.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 19L14 17.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}
