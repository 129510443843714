import ArrowSvg from "./ArrowSvg"
import HomeSvg from "./HomeSvg"
import MapSvg from "./MapSvg"
import PositionSvg from "./PositionSvg"
import SafetySvg from "./SafetySvg"
import UnionToiletSvg from "./UnionToiletSvg"
import ChangeDiaperSvg from "./ChangeDiaperSvg"
import InfoSvg from "./InfoSvg"
import ChevronLeftSvg from "./ChevronLeftSvg"
import ChevronDownSvg from "./ChevronDownSvg"
import ChevronUpSvg from "./ChevronUpSvg"
import MapPinSvg from "./MapPinSvg"
import ExternalSvg from "./ExternalSvg"
import StarSvg from "./StarSvg"
import MailSvg from "./MailSvg"
import TelephoneSvg from "./TelephoneSvg"
import EyeSvg from "./EyeSvg"
import CutlerySvg from "./CutlerySvg"
import AnchorSvg from "./AnchorSvg"
import EventSvg from "./EventSvg"
import GoboatSvg from "./GoboatSvg"
import { ReactComponent as UserPosition } from "./UserPosition.svg"
import FilterSvg from "./FilterSvg"
import XSvg from "./XSvg"
import LinkSvg from "./LinkSvg"
import SwimzoneSvg from "./SwimzoneSvg"
import BaseSvg from "./BaseSvg"
import ShoppingSvg from "./ShoppingSvg"

/* eslint-disable import/prefer-default-export */
export const svgs = {
  Home: HomeSvg,
  Map: MapSvg,
  Safety: SafetySvg,
  Position: PositionSvg,
  Arrow: ArrowSvg,
  UnionToilet: UnionToiletSvg,
  ChangeDiaper: ChangeDiaperSvg,
  Info: InfoSvg,
  ChevronLeft: ChevronLeftSvg,
  ChevronDown: ChevronDownSvg,
  ChevronUp: ChevronUpSvg,
  MapPin: MapPinSvg,
  External: ExternalSvg,
  Star: StarSvg,
  Mail: MailSvg,
  Telephone: TelephoneSvg,
  Eye: EyeSvg,
  Cutlery: CutlerySvg,
  Anchor: AnchorSvg,
  Event: EventSvg,
  GoboatLogo: GoboatSvg,
  Link: LinkSvg,
  Filter: FilterSvg,
  X: XSvg,
  Swimzone: SwimzoneSvg,
  Base: BaseSvg,
  Shopping: ShoppingSvg,
  UserPosition,
}
