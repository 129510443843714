import React, { createContext, useState, useEffect, useContext } from "react"
import fetchWeather from "../util/weather"

const WeatherContext = createContext<{
  temperature?: number
  windSpeed?: number
  weatherSymbolKey?: number
}>({})

export function useWeather() {
  return useContext(WeatherContext)
}

export default function WeatherProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [weatherData, setWeatherData] = useState<{
    temperature?: number
    windSpeed?: number
    weatherSymbolKey?: number
  }>({})

  useEffect(() => {
    fetchWeather().then((data) => setWeatherData(data))
  }, [])
  return (
    <WeatherContext.Provider value={weatherData}>
      {children}
    </WeatherContext.Provider>
  )
}
