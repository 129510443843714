import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function BaseSvg({
  color = "white",
  height = "100%",
  width = "12px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.733 4.27913L7.11955 0.406095C6.49303 -0.163469 5.46781 -0.106513 4.84129 0.406095L0.284782 4.39304C0.113913 4.50696 0 4.67782 0 4.90565C0 5.07652 0.0569565 5.30434 0.170869 5.41826C0.284782 5.53217 0.398695 5.58913 0.569564 5.64608C0.683477 5.70304 0.854346 5.70304 0.968259 5.64608L1.02522 9.40521C1.02522 9.74694 1.13913 10.0317 1.42391 10.2596C1.65174 10.4874 1.99347 10.6013 2.27826 10.6013H4.67042C4.84129 10.6013 5.06912 10.5443 5.18303 10.3735C5.3539 10.2596 5.41086 10.0317 5.41086 9.86086L5.3539 7.01304H6.6639L6.72085 9.86086C6.72085 10.0317 6.77781 10.2026 6.94868 10.3735C7.06259 10.4874 7.29042 10.6013 7.51824 10.6013H9.91041C10.2522 10.6013 10.5369 10.4874 10.7648 10.2596C10.9926 10.0317 11.1065 9.74694 11.1065 9.40521L11.0495 5.64608C11.3343 5.70304 11.6191 5.64608 11.79 5.41826C11.8469 5.3613 11.9039 5.30434 11.9039 5.19043C12.0748 4.84869 12.0178 4.50696 11.733 4.27913ZM3.92999 9.12042H2.61999L2.56304 4.45L5.92346 1.54522C5.98042 1.48827 6.09433 1.48827 6.20825 1.54522L9.62563 4.39304L9.68259 9.06347H8.37259L8.31563 6.21565C8.31563 5.81695 7.97389 5.47521 7.5752 5.47521H4.72738C4.49956 5.47521 4.32869 5.53217 4.21477 5.70304C4.10086 5.87391 3.98695 6.04478 3.98695 6.21565L3.92999 9.12042Z"
        fill={color}
      />
    </svg>
  )
}
