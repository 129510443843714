import React from "react"
import { svgs } from ".."
import { MapPinsProps } from "../../types/Svg"
import cssStyle from "./Pins.module.css"

export default function EventPin({
  style,
  className,
  active = false,
  star,
  onClick,
}: MapPinsProps) {
  const pin = (
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <g filter="url(#filter0_d)">
        <path
          d="M38 18.4C38 27.4575 26 42 22 42C18 42 6 27.4575 6 18.4C6 9.34253 13.1634 2 22 2C30.8366 2 38 9.34253 38 18.4Z"
          fill={active ? "white" : "var(--green)"}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12H15C13.8954 12 13 12.8954 13 14V26C13 27.1046 13.8954 28 15 28H29C30.1046 28 31 27.1046 31 26V14C31 12.8954 30.1046 12 29 12H27C27 11.4477 26.5523 11 26 11C25.4477 11 25 11.4477 25 12H19ZM26 15C25.4477 15 25 14.5523 25 14H19C19 14.5523 18.5523 15 18 15C17.4477 15 17 14.5523 17 14H15L15 26H29V14H27C27 14.5523 26.5523 15 26 15ZM22.3827 16.5019C22.3169 16.349 22.1664 16.25 22 16.25C21.8336 16.25 21.6831 16.349 21.6173 16.5019L20.7012 18.6291L18.395 18.843C18.2293 18.8583 18.0887 18.9708 18.0373 19.1291C17.9858 19.2874 18.0335 19.4611 18.1585 19.5709L19.8986 21.0995L19.3893 23.3589C19.3527 23.5212 19.4163 23.6898 19.5509 23.7876C19.6855 23.8854 19.8654 23.8937 20.0085 23.8087L22 22.6263L23.9915 23.8087C24.1345 23.8937 24.3145 23.8854 24.4491 23.7876C24.5837 23.6898 24.6472 23.5212 24.6107 23.3589L24.1014 21.0995L25.8414 19.5709C25.9665 19.4611 26.0142 19.2874 25.9627 19.1291C25.9113 18.9708 25.7706 18.8583 25.6049 18.843L23.2988 18.6291L22.3827 16.5019Z"
        fill={active ? "var(--green)" : "white"}
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0 0.364706 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )

  if (star) {
    return (
      <div style={{ position: "relative" }}>
        <svgs.Star className={cssStyle.pinStar} height="16px" width="16px" />
        {pin}
      </div>
    )
  }

  return pin
}
