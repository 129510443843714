/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { useCMS } from "../../contexts/SanityContext"
import useSubtitle from "../../hooks/useSubtitle"
import { urlFor } from "../../util/sanity"
import { getCategoryTag, getTagTags } from "../tag/Tag"
import TaggedItem from "../tagged-item/TaggedItem"
import styles from "../../containers/Home.module.css"

export default function Pois() {
  const { pois } = useCMS()
  const getSubtitle = useSubtitle()

  const poisUi = pois
    ?.filter((v, i) => i < 3)
    .map((poi: any, i: number, arr: any[]) => (
      <TaggedItem
        key={poi._id}
        title={poi.title}
        imgSrc={urlFor(poi.hero).size(320, 320).url()!}
        subtitle={getSubtitle(poi)}
        hideBorder={i === arr.length - 1}
        tags={[getCategoryTag(poi), getTagTags(poi)]}
        className={styles.taggedItem}
        star={poi.recommended}
        to={`/poi/${poi._id}`}
      />
    ))

  return <>{poisUi}</>
}
