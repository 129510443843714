import React from "react"
import { svgs } from ".."
import { MapPinsProps } from "../../types/Svg"
import cssStyle from "./Pins.module.css"

export default function SwimzonePin({
  style,
  className,
  active = false,
  star,
  onClick,
}: MapPinsProps) {
  const pin = (
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <path
        d="M32 16.4C32 25.458 20 40 16 40S0 25.457 0 16.4C0 7.343 7.163 0 16 0s16 7.343 16 16.4Z"
        fill={active ? "white" : "var(--blue)"}
      />
      <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#000">
        <path
          d="M4.277 18.661a.279.279 0 0 0-.24.419s.979 1.692 3.061 3c2.082 1.306 5.294 2.232 9.687.765 3.459-1.148 5.804-1.456 7.46-1.422 1.657.034 2.62.403 3.389.655a.279.279 0 0 0 .274-.472c-1.832-1.653-3.63-2.33-5.422-2.478-1.792-.149-3.57.218-5.418.606-3.694.777-7.647 1.662-12.657-1.04a.279.279 0 0 0-.134-.033Z"
          fill={active ? "var(--blue)" : "white"}
        />
        <path
          d="M14.036 11.244a2.155 2.155 0 0 0-.49.067c-.63.16-1.762.61-2.986 1.143a58.995 58.995 0 0 0-3.425 1.614c-.39.203-.656.462-.798.75a1.029 1.029 0 0 0-.034.872c.234.529.923.784 1.682.52 2.376-.823 3.735-1.333 5.107-1.777.398-.127.517-.09.512-.094-.003-.001.004 0 .028.047.024.046.056.127.105.223.15.313.228.473.283.583-1.536 1.18-2.786 2.061-4.505 3.304a.28.28 0 0 0 .099.498s2.162.524 4.627.004c2.403-.506 2.8-.684 4.876-.947h.01c.071-.012.172-.027.283-.073a.573.573 0 0 0 .315-.296c.067-.16.048-.343-.025-.524-.072-.18-.198-.376-.396-.62l-1.982-2.403c-.775-.94-1.506-1.832-1.642-2.01l-.001-.002c-.166-.214-.41-.486-.763-.672a1.806 1.806 0 0 0-.88-.205v-.002Zm8.424.1a2.735 2.735 0 0 0-2.73 2.732 2.736 2.736 0 0 0 2.73 2.73 2.735 2.735 0 0 0 2.731-2.73 2.734 2.734 0 0 0-2.73-2.731Z"
          fill={active ? "var(--blue)" : "white"}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(4 11)" d="M0 0h24v12.837H0z" />
        </clipPath>
      </defs>
    </svg>
  )

  if (star) {
    return (
      <div style={{ position: "relative" }}>
        <svgs.Star className={cssStyle.pinStar} height="16px" width="16px" />
        {pin}
      </div>
    )
  }

  return pin
}
