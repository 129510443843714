/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { useCMS } from "../../contexts/SanityContext"
import { urlFor } from "../../util/sanity"
import { getCategoryTag } from "../tag/Tag"
import TaggedItem from "../tagged-item/TaggedItem"
import styles from "../../containers/Home.module.css"
import useSubtitle from "../../hooks/useSubtitle"

export default function Partnerships() {
  const { partnerships } = useCMS()
  const getSubtitle = useSubtitle()

  const partnershipsUi = partnerships
    ?.filter((v, i) => i < 3)
    .map((partnership: any, i: number, arr: any[]) => (
      <TaggedItem
        key={partnership._id}
        title={partnership.headline}
        imgSrc={urlFor(partnership.hero).size(320, 320).url()!}
        subtitle={getSubtitle(partnership)}
        hideBorder={i === arr.length - 1}
        tags={[getCategoryTag(partnership)]}
        className={styles.taggedItem}
        star={partnership.recommended}
        to={`/partnership/${partnership._id}`}
      />
    ))

  return <>{partnershipsUi}</>
}
