import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import BlockContent from "../BlockContent"
import sanityClient, { urlFor } from "../../util/sanity"
import Screen from "../Screen"
import Faq from "../../components/faq/Faq"
import { ExternalButton } from "../../components/buttons/Button"
import style from "./DetailSafety.module.css"

export default function DetailSafety() {
  const { id } = useParams<{ id: string }>()
  const [safety, setSafety] = useState<any>(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_id == "${id}"]{
          ...
        }`
      )
      .then((data) => setSafety(data[0]))
      .catch()
  }, [])

  const faq = safety?.faq?.map((faqObj: any) => {
    return {
      question: faqObj.questionText,
      answer: faqObj.answerText,
    }
  })

  return (
    <Screen>
      <div className={style.container}>
        {safety?.title && <h1 className={style.subhead}>{safety.title}</h1>}

        <div className={style.imgWrapper}>
          {safety?.hero && (
            <>
              <img
                src={urlFor(safety.hero).size(764, 446).url()!}
                className={style.img}
                alt=""
              />
            </>
          )}
          {safety?.body && <BlockContent blocks={safety.body} />}
          {faq?.length > 0 && <Faq content={faq} />}
          {safety?.cta && (
            <ExternalButton
              text={safety.cta.text}
              onClick={(e) => {
                e.preventDefault()
                window.open(
                  safety.cta.url,
                  "_blank",
                  "noopener noreferrer nofollow"
                )
              }}
            />
          )}
        </div>
      </div>
    </Screen>
  )
}
