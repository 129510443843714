import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function CutlerySvg({
  color = "white",
  height = "100%",
  width = "12px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4V1.5H3V4C3 4.37014 3.2011 4.69331 3.5 4.86622V1.5H4.5V4.86622C4.7989 4.69331 5 4.37014 5 4V1.5H6V4C6 4.93192 5.36261 5.71497 4.5 5.93699V10.5H3.5V5.93699C2.63739 5.71497 2 4.93192 2 4ZM8.50004 1.5C8.04393 1.5 7.71264 1.74465 7.50131 2.07336C7.30559 2.3778 7.19807 2.77025 7.13181 3.1678C6.99997 3.95883 6.99998 4.99178 7 5.96691V6C7 6.27614 7.22386 6.5 7.5 6.5H8.00004V10.5H9.00004V1.5H8.50004Z"
        fill={color}
      />
    </svg>
  )
}
