import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function EyeSvg({
  color = "white",
  height = "100%",
  width = "12px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6C10 6.10516 9.95052 6.31554 9.7458 6.61384C9.54742 6.90291 9.24205 7.21576 8.84614 7.50609C8.04567 8.0931 6.9982 8.5 6 8.5C5.0018 8.5 3.95433 8.0931 3.15386 7.50609C2.75795 7.21576 2.45258 6.90291 2.2542 6.61384C2.04948 6.31554 2 6.10516 2 6C2 5.89484 2.04948 5.68446 2.2542 5.38615C2.45258 5.09709 2.75795 4.78424 3.15386 4.4939C3.95433 3.9069 5.0018 3.5 6 3.5C6.9982 3.5 8.04567 3.9069 8.84614 4.4939C9.24205 4.78424 9.54742 5.09709 9.7458 5.38615C9.95052 5.68446 10 5.89484 10 6ZM11 6C11 7.5 8.5 9.5 6 9.5C3.5 9.5 1 7.5 1 6C1 4.5 3.5 2.5 6 2.5C8.5 2.5 11 4.5 11 6ZM6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8ZM6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z"
        fill={color}
      />
    </svg>
  )
}
