import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import sanityClient, { urlFor } from "../../util/sanity"
import Screen from "../Screen"
import {
  ExternalButton,
  ViewOnMapButton,
} from "../../components/buttons/Button"

import style from "./DetailPartnership.module.css"

/* eslint no-underscore-dangle: 0 */
export default function DetailPartnership() {
  const { id } = useParams<{ id: string }>()
  const [partnership, setPartnership] = useState<any>(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_id == "${id}"]{
          ...
        }`
      )
      .then((data) => setPartnership(data[0]))
      .catch()
  }, [])

  // TODO: add top tags

  return (
    <Screen>
      <div className={style.container}>
        {partnership?.headline && (
          <h1 className={style.subhead}>{partnership.headline}</h1>
        )}
        <div className={style.imgWrapper}>
          {partnership?.hero ? (
            <>
              <img
                src={urlFor(partnership.hero).size(764, 446).url()!}
                className={style.img}
                alt=""
              />
            </>
          ) : (
            <div className={style.img} style={{ background: "grey" }} />
          )}
        </div>
        {partnership?.poi?._ref && (
          <ViewOnMapButton id={partnership.poi._ref} />
        )}

        {partnership?.description && <p>{partnership.description}</p>}
        {partnership?.cta && (
          <ExternalButton
            text={partnership.cta.text}
            onClick={(e) => {
              e.preventDefault()
              window.open(
                partnership.cta.url,
                "_blank",
                "noopener noreferrer nofollow"
              )
            }}
          />
        )}
      </div>
    </Screen>
  )
}
