import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function ShoppingSvg({
  color = "white",
  height = "100%",
  width = "12px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0447 5.49721C7.64246 5.49721 7.37431 5.22905 7.37431 4.82682V2.27933C7.37431 1.81006 6.77095 1.34078 6.03352 1.34078C5.29609 1.34078 4.69274 1.74302 4.69274 2.27933V4.82682C4.69274 5.22905 4.42458 5.49721 4.02235 5.49721C3.62012 5.49721 3.35196 5.22905 3.35196 4.82682V2.27933C3.35196 1.00559 4.55866 0 6.03352 0C7.50838 0 8.71509 1.00559 8.71509 2.27933V4.82682C8.71509 5.22905 8.44693 5.49721 8.0447 5.49721Z"
        fill={color}
      />
      <path
        d="M9.72067 12.4022H2.21229C1.00559 12.4022 0 11.3966 0 10.1899V4.15642C0 3.41899 0.603352 2.81564 1.34078 2.81564H10.6592C11.3966 2.81564 12 3.41899 12 4.15642V10.1899C12 11.3966 10.9944 12.4022 9.72067 12.4022ZM10.6592 4.15642H1.27374V10.1899C1.27374 10.6592 1.67598 11.0615 2.14525 11.0615H9.72067C10.1899 11.0615 10.5922 10.6592 10.5922 10.1899V4.15642H10.6592Z"
        fill={color}
      />
    </svg>
  )
}
