/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react"
import ReactMapGL, { GeolocateControl, FlyToInterpolator } from "react-map-gl"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import Coordinate from "../../types/Coordinate"
import styles from "./Map.module.css"
import useWindowDimensions from "../../containers/useWindowDimensions"

// XXX: workaround for missing reference to 'y' error
const worker =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
// eslint-disable-next-line import/newline-after-import
;(mapboxgl as any).workerClass = worker

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

export type MapProps = {
  point?: Coordinate
  zoom?: number
  height?: number | string
  width?: number | string
  className?: string
  children?: React.ReactNode
  onClick?: () => void
  activeItem: string
}

const activeItemZoomLevel = 14
const defaultZoomLevel = 12

function Map({ point, className, children, onClick, activeItem }: MapProps) {
  const { width, height } = useWindowDimensions()
  const widthMap = width
  const heightMap = height - 106
  const [mapView, setMapView] = useState({
    width: widthMap,
    height: heightMap,
    latitude: point?.latitude,
    longitude: point?.longitude,
    zoom: defaultZoomLevel,
    transitionDuration: 700,
    transitionInterpolator: new FlyToInterpolator(),
  })

  const zoomLevelActive =
    activeItem && mapView.zoom >= 14 ? mapView.zoom : activeItemZoomLevel
  useEffect(() => {
    if (mapView.zoom >= 13) {
      setMapView({
        width: widthMap,
        height: heightMap,
        latitude: mapView.latitude,
        longitude: mapView.longitude,
        zoom: mapView.zoom,
        transitionDuration: 700,
        transitionInterpolator: new FlyToInterpolator(),
      })
    }
    if (activeItem) {
      setMapView({
        width: widthMap,
        height: heightMap,
        latitude: point?.latitude,
        longitude: point?.longitude,
        zoom: zoomLevelActive,
        transitionDuration: 700,
        transitionInterpolator: new FlyToInterpolator(),
      })
    }
  }, [activeItem])

  useEffect(() => {
    setMapView({
      width: widthMap,
      height: heightMap,
      latitude: point?.latitude,
      longitude: point?.longitude,
      zoom: defaultZoomLevel,
      transitionDuration: 0,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }, [height])

  const geolocateControlStyle = {
    left: 10,
    top: 10,
  }
  return (
    <ReactMapGL
      className={className}
      mapboxApiAccessToken={TOKEN}
      touchRotate
      onViewportChange={(v: any) => setMapView({ ...v })}
      onClick={onClick}
      minZoom={12}
      maxZoom={17}
      {...mapView}
    >
      {children}
      <GeolocateControl
        className={styles.geoc}
        style={geolocateControlStyle}
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation
      />
    </ReactMapGL>
  )
}

export default Map
