import React, { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { svgs } from "../../img"
import style from "./Filter.module.css"

export default function Filter({
  text,
  blue = false,
  x = false,
  onClick,
  onXClick,
  className,
}: {
  text?: string
  blue?: boolean
  x?: boolean
  onClick?: () => void
  onXClick?: () => void
  className?: string
}) {
  const longItem = !!text

  const classes = classNames(style.filter, className, {
    [style.longItem]: longItem,
    [style["goboat-blue"]]: blue,
    [style.x]: x,
  })

  return (
    <button type="button" className={classes} onClick={onClick}>
      <div className={style.content}>
        <svgs.Filter color={blue ? "white" : "#2E3D5C"} />
        {text && <p style={{ marginLeft: "4px" }}>{text}</p>}
        {x && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation()
              if (onXClick) onXClick()
            }}
            style={{ paddingTop: "4px" }}
          >
            <svgs.X color={blue ? "white" : "#2E3D5C"} />
          </button>
        )}
      </div>
    </button>
  )
}

export function FiltersList({
  filters,
  className,
  setCheckedFilters,
  hide,
  state,
  setState,
}: {
  filters: { name: string; id: string }[]
  className?: string
  setCheckedFilters: React.Dispatch<React.SetStateAction<string[]>>
  hide: boolean
  state:
    | {
        id: string
        value: boolean
      }[]
    | undefined
  setState: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          value: boolean
        }[]
      | undefined
    >
  >
}) {
  useEffect(() => {
    setState(
      filters.map((filter) => {
        return { id: filter.id, value: false }
      })
    )
  }, [filters])

  useEffect(() => {
    const checkedIdsWithNulls = state?.map((v) => {
      if (v.value) return v.id
      return null
    })

    const checkedIds = checkedIdsWithNulls?.filter(
      (v) => v !== null
    ) as string[]

    setCheckedFilters(checkedIds)
  }, [state])

  const toggle = (id: string) => {
    const newState = state?.map((obj: any) => {
      if (obj.id !== id) return obj
      return { id: obj.id, value: !obj.value }
    })

    setState(newState)
  }

  const classes = classNames(style.list, className, { [style.hide]: hide })

  return (
    <form className={classes}>
      {filters.map((filter) => (
        <div className={style.option} key={filter.id}>
          <input
            type="checkbox"
            defaultChecked={state?.find((v: any) => v.id === filter.id)?.value}
            onClick={() => toggle(filter.id)}
          />
          <button type="button" onClick={() => toggle(filter.id)}>
            {filter.name}
          </button>
        </div>
      ))}
    </form>
  )
}

export function FilterComponent({
  className,
  filters,
  callback,
}: {
  className?: string
  filters?: { name: string; id: string }[]
  callback: (a: any) => void
}) {
  const [showList, setShowList] = useState(false)
  // Array of ids:
  const [checkedFilters, setCheckedFilters] = useState<string[]>([])
  const classes = classNames(style.filterComponent, className)
  const [state, setState] = useState(
    filters?.map((filter) => {
      return { id: filter.id, value: false }
    })
  )
  const node = useRef<HTMLDivElement>(null)
  const handleClickOutside = (e: any) => {
    if (node?.current?.contains(e.target)) {
      return
    }
    setShowList(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    callback(checkedFilters)
  }, [checkedFilters])

  const unSelelctAll = () => {
    setState(
      state?.map((v) => {
        return { id: v.id, value: false }
      })
    )
  }

  if (filters == null) return null
  const text = checkedFilters.length
    ? `${checkedFilters.length} filters`
    : undefined
  return (
    <div ref={node} className={classes}>
      <Filter
        text={text}
        blue={checkedFilters.length !== 0}
        onClick={() => setShowList(!showList)}
        className={style.filter}
        onXClick={unSelelctAll}
        x={!!checkedFilters.length && !showList}
      />
      <FiltersList
        hide={!showList}
        setCheckedFilters={setCheckedFilters}
        className={style.filterList}
        filters={filters}
        state={state}
        setState={setState}
      />
    </div>
  )
}
