import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

// TODO: CREATE TWO CLIENTS
const SanityClient = sanityClient({
  projectId: "j2xknxq9",
  dataset: "production",
  useCdn: true,
})
export default SanityClient

const builder = imageUrlBuilder(SanityClient)
export function urlFor(source: any) {
  return builder.image(source)
}
