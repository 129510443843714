import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function StarSvg({
  className,
  color = "#FFB500",
  height = "100%",
  width = "20px",
}: CustomSvgProps) {
  return (
    <svg
      className={className ?? ""}
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99998 0C10.3994 0 10.7605 0.237649 10.9184 0.604473L13.117 5.70978L18.6518 6.22312C19.0495 6.26 19.3871 6.52999 19.5105 6.90983C19.634 7.28967 19.5195 7.70653 19.2195 7.97012L15.0434 11.6387L16.2656 17.0613C16.3534 17.4509 16.2009 17.8554 15.8778 18.0902C15.5547 18.3249 15.1229 18.3449 14.7795 18.141L9.99998 15.303L5.22047 18.141C4.87706 18.3449 4.44524 18.3249 4.12213 18.0902C3.79901 17.8554 3.64657 17.4509 3.73438 17.0613L4.95652 11.6387L0.780483 7.97012C0.480429 7.70653 0.365995 7.28967 0.489414 6.90983C0.612833 6.52999 0.950434 6.26 1.34812 6.22312L6.88295 5.70978L9.08152 0.604473C9.23949 0.237649 9.60059 0 9.99998 0Z"
        fill={color}
      />
    </svg>
  )
}
