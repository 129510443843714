import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import sanityClient, { urlFor } from "../../util/sanity"
import Screen from "../Screen"
import BlockContent from "../BlockContent"

import style from "./DetailEvent.module.css"
import { ExternalButton } from "../../components/buttons/Button"
import styles from "../Home.module.css"
import { svgs } from "../../img"

export default function DetailEvent() {
  const { id } = useParams<{ id: string }>()
  const [event, setEvent] = useState<any>(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_id == "${id}"]{
          ...
        }`
      )
      .then((data) => setEvent(data[0]))
      .catch()
  }, [])

  return (
    <Screen>
      <div className={style.container}>
        {event?.title && <h1 className={style.subhead}>{event.title}</h1>}

        <div className={style.imgWrapper}>
          {event?.hero != null ? (
            <>
              <img
                src={urlFor(event.hero).size(764, 446).url()!}
                className={style.img}
                alt=""
              />
            </>
          ) : (
            <div className={style.img} style={{ background: "grey" }} />
          )}
        </div>

        <div className={style.timeLayout}>
          <svgs.Info />
          {DateTime.fromISO(event?.datetime).toFormat("D - HH:mm")}
        </div>

        <div className={styles.border} />

        {event?.body != null && (
          <>
            <BlockContent blocks={event.body} className={style.blockContent} />
            <div className={style.buttonWrapper}>
              {event.cta && (
                <ExternalButton
                  className={style.detailsBuyButton}
                  text={event.cta.text}
                  onClick={(e: MouseEvent) => {
                    e.preventDefault()
                    window.open(
                      event.cta.url,
                      "_blank",
                      "noopener noreferrer nofollow"
                    )
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Screen>
  )
}
