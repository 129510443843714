import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function ChevronUp({
  color = "#2E3D5C",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 15.5L12 8.5L19 15.5" stroke={color} strokeWidth="2" />
    </svg>
  )
}
