export type I18nStrings = {
  home: string
  map: string
  safety: string
  showAll: string
  showMore: string
  partnerships: string
  events: string
  articles: string
  article: string
  poi: string
  contactInfo: string
  copenhagen: string
  allArticles: string
  allPartnerships: string
  allPois: string
  allEvents: string
  attraction: string
  restaurant: string
  articleDetails: string
  eventDetails: string
  address: string
  zipCode: string
  phoneNumber: string
  phoneTime: string
  timeNotAvailable: string
  safetyDetails: string
  readMore: string
  poiDetails: string
  viewOnMap: string
  openingHours: string
  topHeader: string
  swimzone: string
  base: string
  shopping: string
  mapLink: string
}

const DK: I18nStrings = Object.freeze({
  home: "Hjem",
  map: "Kort",
  safety: "Sikkerhed",
  showAll: "Vis alle",
  showMore: "Vis mere",
  partnerships: "Partnerskaber",
  events: "Events",
  articles: "Artikler",
  article: "Artikel",
  poi: "Interessepunkter",
  contactInfo: "Kontaktinfo",
  copenhagen: "København",
  allArticles: "Alle Artikler",
  allPartnerships: "Alle Partnerskaber",
  allPois: "Alle Interessepunkter",
  allEvents: "Alle Events",
  attraction: "Attraktion",
  restaurant: "Restaurant",
  articleDetails: "Artikel Detaljer",
  eventDetails: "Event Detaljer",
  address: "Islands Brygge 10",
  zipCode: "2300 København S",
  phoneNumber: "+45 40 26 10 25",
  phoneTime: "(Telefontid: Mandag - Fredag, 10:00 - 13:00)",
  timeNotAvailable: "Tid ikke tilgengængelig",
  safetyDetails: "Sikkerheds Detaljer",
  readMore: "Læs mere",
  poiDetails: "Interessepunkter detaljer",
  viewOnMap: "Se på kort",
  openingHours: "Åbningstider",
  topHeader: "Anbefalet",
  swimzone: "Badezone",
  base: "GoBoat Base",
  shopping: "Shopping",
  mapLink: "https://www.google.com/maps/place/",
})

const EN: I18nStrings = Object.freeze({
  home: "Home",
  map: "Map",
  safety: "Safety",
  showAll: "Show all",
  showMore: "Show more",
  partnerships: "Partnerships",
  events: "Events",
  articles: "Articles",
  article: "Article",
  poi: "Point of interest",
  contactInfo: "Contact info",
  copenhagen: "Copenhagen",
  allArticles: "All Articles",
  allPartnerships: "All Partnerships",
  allPois: "All Point of Interests",
  allEvents: "All Events",
  attraction: "Attraction",
  restaurant: "Restaurant",
  articleDetails: "Article Details",
  eventDetails: "Event Details",
  address: "Islands Brygge 10",
  zipCode: "2300 Copenhagen S",
  phoneNumber: "+45 40 26 10 25",
  phoneTime: "(Phonetime: Monday - Friday, 10:00 - 13:00)",
  timeNotAvailable: "Time Not Available",
  safetyDetails: "Safety Details",
  readMore: "Read more",
  poiDetails: "Point of Interest Details",
  viewOnMap: "View on map",
  openingHours: "Opening hours",
  topHeader: "Recommended",
  swimzone: "Swimzone",
  base: "GoBoat Base",
  shopping: "Shopping",
  mapLink: "https://www.google.com/maps/place/",
})

export const LANGS: Record<string, I18nStrings> = {
  dk: DK,
  en: EN,
}

export type LocaleData = {
  locale: string
  country: string
  strings: I18nStrings
}

const x = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callback: (_data: LocaleData) => {},

  currentLocale: "dk",
  currentStrings: DK,

  get locale(): LocaleData {
    return {
      locale: this.currentLocale.slice(),
      country: "DK",
      strings: { ...this.currentStrings },
    }
  },
}

export function setReactCallback(callback: (data: LocaleData) => void) {
  x.callback = callback
}

export function setCurrentLocale(locale: string) {
  if (LANGS[locale] != null) {
    x.currentLocale = locale
    x.currentStrings = LANGS[locale]
    x.callback(x.locale)
  }
}

export default x.locale
