import React from "react"
import classNames from "classnames"
import { Link, useLocation } from "react-router-dom"
import styles from "./TaggedItem.module.css"
import { svgs } from "../../img"

export default function TaggedItem({
  imgSrc,
  title,
  tags,
  subtitle,
  className,
  hideInfo,
  hideBorder = false,
  text,
  button,
  star,
  to,
  starHack = false,
}: {
  imgSrc?: string
  tags?: React.ReactNode[]
  title: string
  subtitle?: string
  className?: string
  hideInfo?: boolean
  hideBorder?: boolean
  text?: string
  button?: React.ReactNode
  star?: boolean
  to?: string
  starHack?: boolean
}) {
  const classes = classNames(styles.wrapper, className)
  const contentClasses = classNames(styles.content, {
    [styles.hideBorder]: hideBorder,
  })
  const { pathname } = useLocation()
  const starClasses = classNames(styles.star, { [styles.starHack]: starHack })
  const starMapClasses = classNames(styles.notStarMap)

  const content = (
    <>
      <div className={styles.item}>
        {imgSrc ? (
          <img src={imgSrc} alt="img" className={styles.img} />
        ) : (
          <div className={styles.imgPlaceholder} />
        )}
        <div className={contentClasses}>
          <p className={styles.title}>{title}</p>
          <p className={styles.info}>
            {!hideInfo && subtitle && <svgs.Info />} {subtitle}
          </p>
          <div className={styles.tags}>
            {tags}
            {star && (
              <span
                className={`${
                  !pathname.includes("/map") ? starMapClasses : starClasses
                }`}
              >
                <svgs.Star />
              </span>
            )}
          </div>
        </div>
      </div>
      <p className={styles.text}>{text}</p>
      {button}
    </>
  )

  return to ? (
    <Link to={to} className={classes}>
      {content}
    </Link>
  ) : (
    <div className={classes}>{content}</div>
  )
}
