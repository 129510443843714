import React from "react"
import { CustomSvgProps } from "../../types/Svg"

export default function CloudySkySvg({
  color = "white",
  height = "100%",
  width = "24px",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 12C21 15.3137 18.3137 18 15 18H7C4.79086 18 3 16.2091 3 14C3 11.7909 4.79086 10 7 10C7.79432 10 8.53457 10.2315 9.15693 10.6308C9.77641 7.97685 12.1573 6 15 6C18.3137 6 21 8.68629 21 12Z"
        />
      </mask>
      <path
        d="M9.15693 10.6308L8.07701 12.3142L10.4608 13.8434L11.1046 11.0854L9.15693 10.6308ZM15 20C19.4183 20 23 16.4183 23 12H19C19 14.2091 17.2091 16 15 16V20ZM15 16H7V20H15V16ZM1 14C1 17.3137 3.68629 20 7 20V16C5.89543 16 5 15.1046 5 14H1ZM7 8C3.68629 8 1 10.6863 1 14H5C5 12.8954 5.89543 12 7 12V8ZM10.2369 8.94741C9.3019 8.34762 8.18849 8 7 8V12C7.40016 12 7.76725 12.1155 8.07701 12.3142L10.2369 8.94741ZM15 4C11.2072 4 8.03528 6.63744 7.20929 10.1762L11.1046 11.0854C11.5175 9.31626 13.1075 8 15 8V4ZM23 12C23 7.58172 19.4183 4 15 4V8C17.2091 8 19 9.79086 19 12H23Z"
        fill={color}
        mask="url(#path-1-inside-1)"
      />
    </svg>
  )
}
