import React from "react"
import { CustomSvgProps } from "../types/Svg"

export default function MapSvg({
  color = "#959DAD",
  width = "30px",
  height = "100%",
}: CustomSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 4L4.5 5.99999V20L9.5 18M9.5 4V18M9.5 4L14.5 6M9.5 18L14.5 20M14.5 6L19.5 4V18L14.5 20M14.5 6V20"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
